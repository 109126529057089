import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Notifier from 'common/helpers/Notifier';
import { browserHistory } from 'common/utils/browserHistory';
import {
  BaseCard,
} from '@unite-us/app-components';
import {
  useInvalidateQueries,
} from 'src/api/APIHooks';
import { submitAssessment } from 'src/pages/facesheet/_id/eligibility/hooks/eligibilityApi';
import {
  EL_ASSESSMENT_QUERY_KEYS,
} from 'src/pages/facesheet/_id/eligibility/hooks/useEligibilityAssessments';
import {
  ELIGIBILITY_ASSESSMENT_STATUS,
} from 'src/pages/facesheet/_id/eligibility/utils';

const BUTTON_STYLE_A = 'h-12 px-16 py-3 border border-solid border-medium-border-blue rounded text-action-darker-blue';

const EligibilityConsent = ({
  personId,
  setLoading,
  // employeeId,
  answerConsent,
}) => {
  const invalidateQueries = useInvalidateQueries();
  const answer = async (value) => {
    try {
      setLoading(true);
      invalidateQueries(EL_ASSESSMENT_QUERY_KEYS.ASSESSMENTS);
      // user declined Eligibility (Assessment) Consent
      if (value === false) {
        const response = await submitAssessment({ personId, status: ELIGIBILITY_ASSESSMENT_STATUS.DECLINED });
        const screenId = get(response, 'data.screen.id');
        setLoading(false);
        if (screenId) {
          Notifier.dispatch(200, 'Eligibility Assessment Declined');
          browserHistory.push(`/facesheet/${personId}/eligibility/view/${screenId}`);
          return;
        }
      }

      setLoading(false);
      answerConsent(value);
    } catch (error) {
      setLoading(false);
      // rollbar error here
    }
  };

  return (
    <div className="form-submissions">
      <BaseCard className="bg-white">
        <div
          className="border border-solid border-medium-border-blue border-l-0 border-r-0 border-t-0"
        >
          <div className="pl-4 py-6">
            <h2 className="text-xl font-bold">Agreement to Eligibility Assessment</h2>
          </div>
        </div>

        <div className="bg-light-fill-grey px-6 pt-4 pl-4">
          <p className="text-md">
            {
              'I would like to see, based on your medical history including information Medicaid ' +
              'collects, if the client qualifies for any services that could be helpful and which ' +
              'would be free to the client. Is this okay with the client? Do they have any questions?'
            }
          </p>
          <p className="text-md mt-5">
            {
              'Please confirm that the information provided during this assessment is accurate ' +
              'to the best of the client\'s knowledge.'
            }
          </p>
          <div className="mt-24 pb-6">
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => answer(false)}
                className={BUTTON_STYLE_A}
              >
                <span className="inline-block align-middle">Client Declines</span>
              </button>

              <button
                type="button"
                onClick={() => answer(true)}
                className="h-12 px-16 py-3 bg-action-blue rounded text-white"
              >
                <span className="inline-block align-middle text-white">Client Accepts</span>
              </button>
            </div>
          </div>
        </div>
      </BaseCard>
    </div>
  );
};

EligibilityConsent.propTypes = {
  setLoading: PropTypes.func.isRequired,
  answerConsent: PropTypes.func.isRequired,
  // employeeId: PropTypes.string.isRequired,
  personId: PropTypes.string.isRequired,
};

export default EligibilityConsent;
