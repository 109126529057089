import canSendDraft from './canSendDraft';

function sendDraft({
  caseReferrals,
  groupId: providerId,
  onSubmit,
  referral,
  user,
  crtb1239AdaptDraftReferralFlowEnabled,
}) {
  return canSendDraft({
    caseReferrals, providerId, referral, user,
  }) ? {
    label: crtb1239AdaptDraftReferralFlowEnabled ? 'Edit & Send' : 'Send',
    value: 'sendDraft',
    className: 'take-action-send-draft',
    action: onSubmit,
  } : null;
}

export default sendDraft;
