import React from 'react';
import PropTypes from 'prop-types';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Button,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from '@unite-us/ui';
import Pagination from 'src/common/Pagination/Pagination';
import EmptyTable from 'src/common/tables/EmptyTable/EmptyTable';
import { isEmpty } from 'lodash';

const DynamicDataTable = ({
  title, withPadding, button, table, withPagination, pagination,
}) => {
  const tableBorder = table.withBorder ? 'rounded-b-none' : 'border-0 border-t rounded-none';
  const emptyTableBorder = table.withBorder ? 'p-6 border border-t-0 border-solid border-pale-grey' : 'p-6';

  return (
    <div className="dynamic-data-table-container">
      <BaseCard className="p-6 pb-0" noBorder>
        <BaseCardHeader title={title} noBorder className="px-4 font-bold">
          <Button
            id="edit-attachment-button"
            iconLeft={button.icon}
            className="flex"
            label={button.label}
            onClick={button.onClick}
            disabled={button.disable}
          />
        </BaseCardHeader>
        <BaseCardBody withPadding={withPadding}>
          <Table className={tableBorder}>
            <TableHeader>
              <TableRow>
                {table.headers.map((header) => (
                  <TableHeaderColumn
                    key={header}
                  >
                    {header}
                  </TableHeaderColumn>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {table.data.map(({ rowId, data }) => (
                <TableRow key={`data-row-${rowId}`}>
                  {Object.keys(data).map((key) => (
                    <TableRowColumn key={key}>
                      {data[key]}
                    </TableRowColumn>
                ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {isEmpty(table.data) && (
            <EmptyTable
              className={emptyTableBorder}
              message={table.emptyTableMessage}
            />
          )}
          {withPagination && (
            <div className="mt-6 mb-6">
              <Pagination
                pageNumber={pagination.pageNumber}
                pageSize={pagination.pageSize}
                totalItemCount={pagination.totalItemCount}
                totalPageCount={pagination.totalPageCount}
                setPageSize={pagination.setPageSize}
                setPageNumber={pagination.setPageNumber}
                scrollPaginationElement={pagination.scrollPaginationElement}
              />
            </div>
          )}
        </BaseCardBody>
      </BaseCard>
    </div>
  );
};

DynamicDataTable.propTypes = {
  title: PropTypes.string.isRequired,
  withPadding: PropTypes.bool,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.element,
    onClick: PropTypes.func.isRequired,
    dataTestId: PropTypes.string,
    disable: PropTypes.bool,
  }).isRequired,
  table: PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      rowId: PropTypes.string.isRequired,
      data: PropTypes.object.isRequired,
    })).isRequired,
    emptyTableMessage: PropTypes.string,
    withBorder: PropTypes.bool,
  }).isRequired,
  withPagination: PropTypes.bool,
  pagination: PropTypes.shape({
    pageNumber: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalItemCount: PropTypes.number.isRequired,
    totalPageCount: PropTypes.number.isRequired,
    setPageSize: PropTypes.func.isRequired,
    setPageNumber: PropTypes.func.isRequired,
    scrollPaginationElement: PropTypes.func.isRequired,
  }),
};

DynamicDataTable.defaultProps = {
  withPadding: false,
  withPagination: false,
  pagination: {
    pageNumber: 1,
    pageSize: 10,
    totalItemCount: 0,
    totalPageCount: 0,
    setPageSize: () => {},
    setPageNumber: () => {},
    scrollPaginationElement: () => {},
  },
};

export default DynamicDataTable;
