import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import {
  BaseCard,
  BaseCardBody,
  Button,
} from '@unite-us/app-components';
// import { Icon } from '@unite-us/ui';
import { browserHistory } from 'common/utils/browserHistory';
import { BackButton } from 'common/buttons';
import { screeningsApiClient } from 'src/api/config';
import {
  formatDateToScreeningFormat,
  isClientEligible,
  ELIGIBILITY_ASSESSMENT_STATUS,
  SELECT_SCREENING_QUESTION,
} from 'src/pages/facesheet/_id/eligibility/utils';
import StatusBadge from 'src/pages/facesheet/_id/eligibility/components/StatusBadge';

const ViewEligibilityAssessment = (props) => {
  const [assessment, setAssessment] = useState(null);
  const assessment_id = get(props, 'params.assessment_id');
  const contactId = get(props, 'params.id');
  const onBackClick = () => {
    browserHistory.push(`/facesheet/${contactId}/eligibility/all`);
  };

  const fetchAssessment = async () => {
    const response = await screeningsApiClient.get(`/screenings/${assessment_id}`);
    if (get(response, 'data.screen.questions')) {
      const questions = response.data.screen.questions;
      response.data.screen.questions = questions.sort((a, b) => a.order - b.order);
      response.renderQuestions = questions.filter((q) => {
        if (q.primaryText === SELECT_SCREENING_QUESTION) {
          return false;
        }
        return true;
      });
    }
    const screen = get(response, 'data.screen');

    setAssessment({
      ...response,
      isEligible: isClientEligible(screen),
    });
  };

  useEffect(() => {
    if (!assessment) {
      fetchAssessment();
    }
  }, []);

  if (!assessment) { return null; }

  const addSocialCoverage = () => (
    browserHistory.push(`/facesheet/${contactId}/profile`)
  );

  const searchForResources = () => (
    browserHistory.push(`/referrals/create/add-resources?person=${contactId}`)
  );

  // const edit = () => (
  //   browserHistory.push(`/facesheet/${contactId}/eligibility/edit/${assessment_id}`)
  // );

  const { isEligible } = assessment;
   const renderQuestions = get(assessment, 'renderQuestions', []);
  const status = get(assessment, 'data.screen.status', '');
  const updatedAt = get(assessment, 'data.screen.updatedAt');
  const savedDate = updatedAt ? `Saved ${formatDateToScreeningFormat(updatedAt)}` : null;
  const hasNoAnswers = renderQuestions.filter((q) => get(q, 'answer.string', null)).length === 0;

  const draftMessage = 'Click Edit and complete the assessment to determine the client\'s' +
    'eligibility for enhanced health-related social need services.';
  const declinedMessage = 'The client has declined to conduct an eligibility assessment. You can' +
    ' refer the client to existing services for any identified needs.';
  const completeAndEligibleMessage = 'Based on the assessment the client is eligible for some enhanced HRSN ' +
    'services. Add social care coverage to enable referrals to enhanced HRSN programs.';
  const completeAndNotEligibleMessage = 'Based on the assessment the client is not eligible for enhanced ' +
    'HRSN services. You can refer the client to non-enhanced services for any identified needs.';
  const isDraft = status === ELIGIBILITY_ASSESSMENT_STATUS.DRAFT;
  const isComplete = status === ELIGIBILITY_ASSESSMENT_STATUS.COMPLETE;
  const isDeclined = status === ELIGIBILITY_ASSESSMENT_STATUS.DECLINED;
  let rightSideTitle = '';
  if (isDraft) { rightSideTitle = 'Assessment Saved as Draft'; }
  if (isDeclined) { rightSideTitle = 'Client Declined'; }
  if (isComplete && isEligible) { rightSideTitle = 'Client Eligible'; }
  if (isComplete && !isEligible) { rightSideTitle = 'Client Not Eligible'; }

  return (
    <div>
      <BackButton
        label="Back to Eligibility Assessments"
        navigateTo={onBackClick}
      />
      <div className="mt-6">
        <div className="w-2/3 inline-block">
          <BaseCard className="bg-white">
            <div
              className="border border-solid border-medium-border-blue border-l-0 border-r-0 border-t-0"
            >
              <div className="px-10 py-8">
                <h2 className="text-2xl font-bold mb-2">Eligibility Assessment</h2>
                {/* <p className="text-md">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                <div className="mt-5">
                  <div className="mr-3 inline-block">
                    <StatusBadge status={status} />
                  </div>
                  { savedDate ? <p className="inline-block">{ savedDate }</p> : null }
                  {/* <div className="inline-block float-right align-top">
                    <button type="button" onClick={edit}>
                      <Icon icon="IconPencil" className="mt-quarter" />
                      <span className="inline-block pt-1 pl-1 align-top text-md font-bold">
                        Edit
                      </span>
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
            <BaseCardBody>
              <div className="ml-10 my-8">
                {
                  hasNoAnswers ? (
                    <p className="text-dark-blue font-light text-base">
                      No answers have been provided for this eligibility assessment.
                    </p>
                  ) : null
                }
                {
                  renderQuestions
                    .filter((q) => {
                      const answers = q.answers;
                      const answer = q.answer;
                      if ((!answers || !answers.length) && !answer) {
                        return false;
                      }
                      return true;
                    })
                    .map((q) => {
                      let answerString = '';
                      if (q.answer) {
                        answerString = q.answer.string;
                      }
                      if (q.answers) {
                        answerString = q.answers.map((o) => (
                          <p className="text-brand-blue font-light text-base">
                            { o.string }
                          </p>
                        ));
                      }
                      return (
                        <div className="mb-16">
                          <p className="text-dark-blue font-black text-base mb-2">
                            { q.primaryText }
                          </p>
                          <p className="text-brand-blue font-light text-base">
                            { answerString }
                          </p>
                        </div>
                      );
                    })
                }
              </div>
            </BaseCardBody>
          </BaseCard>
        </div>
        <div className="w-1/3 inline-block align-top">
          <div className="ml-5 bg-light-fill-grey">
            <BaseCard className="">
              <div className="px-6 py-6">
                <h2 className="text-xl font-bold mb-2">
                  { rightSideTitle }
                </h2>
                {
                  isDraft ? (
                    <div>
                      <p className="mb-4">
                        { draftMessage }
                      </p>
                      <Button
                        id="search-for-resources-btn"
                        onClick={searchForResources}
                        label="Search for Resources"
                        className="w-full"
                        primary
                      />
                    </div>
                  ) : null
                }
                {
                  isDeclined ? (
                    <div>
                      <p className="mb-4">
                        { declinedMessage }
                      </p>
                      <Button
                        id="search-for-resources-btn"
                        onClick={searchForResources}
                        label="Search for Resources"
                        className="w-full"
                        primary
                      />
                    </div>
                  ) : null
                }
                {
                  isComplete ? (
                    <div>
                      <p className="mb-4">
                        {
                          isEligible ? (
                            <div>
                              <p className="mb-4">
                                { completeAndEligibleMessage}
                              </p>
                              <Button
                                id="add-social-coverage-btn"
                                onClick={addSocialCoverage}
                                label="Add Social Coverage"
                                className="w-full"
                                primary
                              />
                            </div>
                          ) : (
                            <div>
                              <p className="mb-4">
                                { completeAndNotEligibleMessage}
                              </p>
                              <Button
                                id="search-for-resources-btn"
                                onClick={searchForResources}
                                label="Search for Resources"
                                className="w-full"
                                primary
                              />
                            </div>
                          )
                        }
                      </p>
                    </div>
                  ) : null
                }
              </div>
            </BaseCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewEligibilityAssessment;
