import { useState } from 'react';
import { capitalize } from 'lodash/string';
import { useCurrentPayerId } from 'common/contexts/CurrentProviderContext';
import { useFind } from 'src/api/APIHooks';
import { REQUEST_TYPES } from '../constants';

export const useEnrollmentFilters = () => {
  const currentPayerId = useCurrentPayerId();

  const { data: planData } = useFind('plan', {
    payer: currentPayerId,
    plan_type: 'social',
    enrollment_required: true,
  }, { queryConfig: { placeholderData: undefined } });
  const plans = planData?.data?.data ?? [];
  const planOptions = plans.map((plan) => ({
    label: plan.name,
    value: plan.id,
  }));

  const [requestType, setRequestType] = useState(undefined);
  const [plan, setPlan] = useState(undefined);
  const [daysSinceLastUpdated, setDaysSinceLastUpdated] = useState(undefined);
  const [clientName, setClientName] = useState(undefined);

  const isFiltered = !!requestType || !!plan || !!daysSinceLastUpdated || !!clientName;

  const resetFilters = () => {
    setRequestType(undefined);
    setPlan(undefined);
    setDaysSinceLastUpdated(undefined);
    setClientName(undefined);
  };

  const daysSinceLastUpdatedOptions = [
    {
      label: 'Today',
      value: 1,
    },
    {
      label: 'Last 7 days',
      value: 7,
    },
    {
      label: 'Last 14 days',
      value: 14,
    },
    {
      label: 'Last 30 days',
      value: 30,
    },
    {
      label: 'Last 60 days',
      value: 60,
    },
  ];

  return {
    filterValues: {
      requestType,
      plan,
      daysSinceLastUpdated,
      clientName,
    },
    filterOptions: {
      requestType: Object.values(REQUEST_TYPES).map((type) => (
        { value: type, label: capitalize(type) }
      )),
      plan: planOptions,
      daysSinceLastUpdated: daysSinceLastUpdatedOptions,
    },
    isFiltered,
    resetFilters,
    setRequestTypeFilter: setRequestType,
    setPlanFilter: setPlan,
    setDaysSinceLastUpdatedFilter: setDaysSinceLastUpdated,
    setClientNameFilter: setClientName,
  };
};
