/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { get, upperFirst } from 'lodash';
import { connect } from 'react-redux';
import { isEmpty, wget } from 'lodash';
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableBody,
  BaseCard,
  BaseCardBody,
  TableRowColumn
} from '@unite-us/ui';
import StatusBadge from 'src/pages/facesheet/_id/eligibility/components/StatusBadge';
import { browserHistory } from 'common/utils/browserHistory';
import { getContactFromState } from 'src/components/Contacts/utils';
import AddCirclePlusButton from 'common/display/Profile/components/AddCirclePlusButton';
import { useEligibilityAssessments } from 'src/pages/facesheet/_id/eligibility/hooks/useEligibilityAssessments';

export function EligibilityAssessments({
  contact,
  children,
  employeeFullName,
}) {
  const { data: assessments, isFetching } = useEligibilityAssessments(contact.id);
  const hasFilters = false;

  const openAssessment = (assessment) => {
    browserHistory.push(`/facesheet/${contact.id}/eligibility/view/${assessment.id}`);
  };

  if (children) {
    return children;
  }

  return (
    <div className="eligibility-assessments">
      <div className="mb-5 flex justify-end" aria-hidden="true">
        <h2 className="w-9/12 mb-4 my-2">Eligibility Assessments</h2>
        <div className="w-3/12 flex justify-end">
          <AddCirclePlusButton
            id="add-new-assessment"
            labelText="Add new assessment"
            addHandler={() => browserHistory.push(`/facesheet/${contact.id}/eligibility/new`)}
          />
        </div>
      </div>

      <div>
        <BaseCard>
          <BaseCardBody>
            <Table
              noBorder
            >
              <TableHeader className="form-submissions-header">
                <TableRow>
                  <TableHeaderColumn>
                    Form
                  </TableHeaderColumn>
                  <TableHeaderColumn>
                    Submitter
                  </TableHeaderColumn>
                  <TableHeaderColumn>
                    Status
                  </TableHeaderColumn>
                  <TableHeaderColumn>
                    Organization
                  </TableHeaderColumn>
                  <TableHeaderColumn>
                    Last Updated
                  </TableHeaderColumn>
                </TableRow>
              </TableHeader>
              {
                assessments && assessments.length ? (
                  <TableBody>
                    {
                      assessments.map((assessment, i) => {
                        const lastUpdated = assessment.updatedAt ? moment(assessment.updatedAt).format('LL') : '';
                        return (
                          <TableRow
                            onCellClick={() => openAssessment(assessment) }
                            key={`el_assess_${i}`}
                          >
                            <TableRowColumn>
                              Eligibility Assessment
                            </TableRowColumn>
                            <TableRowColumn>
                              TBD
                            </TableRowColumn>
                            <TableRowColumn>
                              <StatusBadge status={ upperFirst(get(assessment, 'status', '-')) } />
                            </TableRowColumn>
                            <TableRowColumn>
                              { get(assessment, 'provider.name', '') }
                            </TableRowColumn>
                            <TableRowColumn>
                              { lastUpdated }
                            </TableRowColumn>
                          </TableRow>
                        );
                      })
                    }
                  </TableBody>
                ) : null
              }
              
            </Table>
            {isFetching && (
              <div className="flex justify-center p-6">
                <p>
                  Loading...
                </p>
              </div>
            )}
            {!isFetching && isEmpty(assessments) && (
              <div className="flex justify-center p-6">
                <p>
                  {!hasFilters && `There are no eligibility assessments for ${contact.full_name}`}
                  {hasFilters && (
                    'No eligibility assessments match your search criteria. Please try adjusting the filters and search again.'
                  )}
                </p>
              </div>
            )}
          </BaseCardBody>
        </BaseCard>
      </div>
    </div>
  );
};

EligibilityAssessments.propTypes = {
  contact: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired,
  employeeFullName: PropTypes.string.isRequired,
  children: PropTypes.node,
};

EligibilityAssessments.defaultProps = {
  children: null,
};

function mapStateToProps(state, ownProps) {
  const contactId = wget(ownProps, 'params.id', '');
  const contact = getContactFromState(state, contactId);
  const currentProvider = state.globalState.currentProvider.group;
  return {
    contact,
    provider: currentProvider,
    employeeFullName: get(state, 'globalState.currentEmployee.full_name'),
  };
}

export default connect(mapStateToProps)(EligibilityAssessments);
