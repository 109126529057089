import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Icon } from '@unite-us/ui';
import callOrLog from 'src/common/utils/callOrLog';
import { SEARCH } from 'common/utils/EventTracker/utils/eventConstants';
import { fetchSearchResults } from 'actions/Search/Group';
import { goToSearchIndex } from './utils';
import SearchBarOverlay from './components/SearchBarOverlay';

import './stylesheets/searchBar.scss';

export class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      searchResults: {},
      showResultsDropdown: false,
      total_count: 0,
    };

    this.clearSearchResults = this.clearSearchResults.bind(this);
    this.closeSearchResults = this.closeSearchResults.bind(this);
    this.fetchSearchResults = this.fetchSearchResults.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getChildContext() {
    const {
      closeSearchResults,
      isFetching,
      session,
    } = this.props;

    const {
      query,
      searchResults,
    } = this.state;

    return {
      closeSearchResults,
      isFetching,
      query,
      searchResults,
      session,
    };
  }

  // eslint-disable-next-line react/sort-comp
  fetchSearchResults(query) {
    const { groupId } = this.context;

    this.props.fetchSearchResults({ groupId, query })
      .then((searchResults) => {
        this.setState({
          searchResults,
          // eslint-disable-next-line max-len
          total_count: (searchResults.contact && searchResults.contact.meta.page.total_count) ? searchResults.contact.meta.page.total_count : 0,
        });
      });
  }

  clearSearchResults() {
    this.setState({
      searchResults: {},
      showResultsDropdown: false,
    });
  }

  closeSearchResults() {
    this.props.closeSearchResults();
    callOrLog(() => this.context.eventTracker(SEARCH.closeSearchBar));
  }

  handleInputChange(event) {
    this.setState({
      query: event.target.value,
    }, () => {
      if (this.state.query && this.state.query.length > 0) {
        this.setState({ showResultsDropdown: true });
        this.fetchSearchResults(this.state.query);
      }
      if (!this.state.query) {
        this.clearSearchResults();
      }
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    const query = this.state.query;
    if (query.length > 0) {
      this.props.closeSearchResults();
      callOrLog(() => this.context.eventTracker(SEARCH.searchIndex));
      goToSearchIndex({ query });
    }
  }

  render() {
    return (
      <div className="search-bar">
        <div className="search-bar__form-container">
          <Icon
            icon="IconSearch"
            className="search-bar__button-search"
            color={'#FFFFFF'}
            size={20}
          />

          <form className="search-bar__form" onSubmit={this.handleSubmit}>
            <label htmlFor="search-bar__input-field" className="sr-only">Search</label>
            <input
              id="search-bar__input-field"
              className="search-bar__input"
              autoComplete="off"
              autoFocus
              onChange={this.handleInputChange}
              placeholder="Search"
              type="search"
              value={this.state.query}
              aria-label={`${this.state.total_count} results`}
              aria-live="assertive"
            />
          </form>

          <Icon
            className="search-bar__button-clear"
            color={'#FFFFFF'}
            icon="IconCross"
            onClick={this.closeSearchResults}
            size={18}
            ariaLabel="Close client search bar"
          />

          <SearchBarOverlay
            showResultsDropdown={this.state.showResultsDropdown}
          />
        </div>
      </div>
    );
  }
}

SearchBar.propTypes = {
  closeSearchResults: PropTypes.func.isRequired,
  fetchSearchResults: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  session: PropTypes.object.isRequired,
};

SearchBar.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
};

SearchBar.childContextTypes = {
  closeSearchResults: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  query: PropTypes.string.isRequired,
  searchResults: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    isFetching: get(state, 'search.isFetching', false),
    session: get(state, 'session', {}),
  };
}

export default connect(
  mapStateToProps,
  { fetchSearchResults },
)(SearchBar);
