import { template } from 'lodash';

// CBO Invoice Statuses
export const ACCEPTED = 'Accepted';
export const DRAFT = 'Draft';
export const IN_DISPUTE = 'In Dispute';
export const IN_QUEUE_TO_PAYER = 'In Queue to Payer';
export const REJECTED = 'Rejected';
export const REJECTED_BY_PAYER = 'Rejected by Payer';
export const REJECTED_BY_NETWORK_LEAD = 'Rejected by Network Lead';
export const SUBMITTED_TO_NETWORK_LEAD = 'Submitted to Network Lead';
export const TRANSMITTED_TO_PAYER = 'Submitted to Payer';
export const PAID = 'Paid';
export const NEEDS_ATTENTION = 'Needs Attention';
export const AWAITING_TRANSMISSION = 'Awaiting Transmission';

export const PENDING_AND_APPROVED_STATUSES = [
  'submitted_contracted_service_note',
  'submitted_to_network_lead',
  'submitted_to_payer',
  'transmitted_to_payer',
  'accepted_by_payer',
  'paid',
];

export const CBO_ADMIN_ARCHIVABLE_STATUSES = [
  'submitted_contracted_service_note',
  'accepted_by_payer',
  'rejected_by_cbo_admin',
  'rejected_by_network_lead',
  'rejected_by_payer',
  'paid',
];
export const NETWORK_LEAD_ARCHIVABLE_STATUSES = [
  'submitted_to_network_lead',
  'accepted_by_payer',
  'rejected_by_network_lead',
  'rejected_by_payer',
  'paid',
];

export const READ_ONLY_BULK_ACTION_ICONS = [
  'Download',
  'Archive',
  'Unarchive',
];

export const ROLES = {
  networkLead: 'network_lead',
  payerProvider: 'payer_provider',
  cboAdmin: 'cbo_admin',
};

export const REJECTED_OPTIONS = ['rejected_by_network_lead', 'rejected_by_payer', 'rejected_by_cbo_admin'];
export const DISPUTABLE_STATUSES = ['paid', 'accepted_by_payer', 'rejected_by_payer'];

export const HISTORY_ICONS = {
  submitted_contracted_service_note: 'IconPlusCircle',
  submitted_to_network_lead: 'IconSubmit',
  submitted_to_payer: 'IconSubmit',
  transmitted_to_payer: 'IconSubmit',
  rejected_by_network_lead: 'IconTimesCircle',
  rejected_by_payer: 'IconTimesCircle',
  rejected_by_cbo_admin: 'IconTimesCircle',
  accepted_by_payer: 'V2CheckCircle',
  under_dispute: 'IconExclamationCircle',
  payment_received: 'IconDollarSign',
  edited: 'V2Edit',
};

export const HISTORY_MESSAGES = {
  submitted_contracted_service_note: template('Created by <%= employeeName %>'),
  submitted_to_network_lead: template('Submitted by <%= employeeName %> to Network Lead'),
  submitted_to_payer: template('Submitted by <%= employeeName %> to Payer'),
  rejected_by_network_lead: template('Rejected by <%= employeeName %>'),
  rejected_by_payer: template('Rejected by <%= employeeName %>'),
  rejected_by_cbo_admin: template('Rejected by <%= employeeName %>'),
  accepted_by_payer: template('Accepted by <%= employeeName %>'),
  transmitted_to_payer: template('Transmitted to Payer'),
  under_dispute: template('Disputed by <%= employeeName %>'),
  payment_received: template('Payment received by <%= employeeName %>'),
  edited: template('Edited by <%= employeeName %>'),
};

// Invoice status params
export const ACCEPTED_STATUSES = 'paid,accepted_by_payer';
export const DISPUTED_STATUSES = 'paid,accepted_by_payer,rejected_by_payer';
export const DRAFT_STATUSES = 'submitted_contracted_service_note,rejected_by_cbo_admin';
export const SUBMITTED_NETWORK_LEAD_STATUSES = 'submitted_to_payer,transmitted_to_payer';
export const SUBMITTED_CBO_ADMIN_STATUSES = 'submitted_to_network_lead,submitted_to_payer,transmitted_to_payer';
export const REJECTED_NETWORK_LEAD_STATUSES = 'rejected_by_network_lead,rejected_by_payer';
export const REJECTED_CBO_ADMIN_STATUSES = 'rejected_by_cbo_admin,rejected_by_network_lead,rejected_by_payer';
export const REJECTED_CBO_ADMIN_STATUSES_DWQ = 'rejected_by_network_lead,rejected_by_payer';
export const OPEN_NL_STATUSES = 'submitted_to_network_lead,submitted_to_payer,transmitted_to_payer';
export const CLOSED_NL_STATUSES = 'accepted_by_payer,paid,rejected_by_network_lead,rejected_by_payer';
export const OPEN_PAYER_STATUSES = 'transmitted_to_payer,accepted_by_payer';
export const READ_ONLY_OPEN_PAYER_STATUSES = 'submitted_to_payer,transmitted_to_payer,accepted_by_payer';
export const CLOSED_PAYER_STATUSES = 'paid,rejected_by_payer';
export const ALL_PAYER_STATUSES = `${OPEN_PAYER_STATUSES},${CLOSED_PAYER_STATUSES}`;
export const PAYER_ARCHIVED_STATUS_OPTIONS = 'paid,rejected_by_payer';
export const READ_ONLY_ALL_PAYER_STATUSES = `${READ_ONLY_OPEN_PAYER_STATUSES},${CLOSED_PAYER_STATUSES}`;
export const PAYER_DISPUTED_STATUSES = 'paid,rejected_by_payer';
