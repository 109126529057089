import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFindProviderRequired } from 'src/components/Organization/api/hooks/v1/providerHooks';
import { connect } from 'react-redux';
import { flow, get, isEmpty } from 'lodash';
import { DashboardNavigationLink, LeftNavigationBar } from 'src/common/Navigation';
import FeatureFlagContainer from 'src/common/utils/FeatureFlags/FeatureFlagContainer';
import { useFeatureFlag } from 'src/common/hooks';
import { hasInvoicesAccess, hasPayerInvoicesRole, useInvoiceDisputeWorkflow } from 'src/common/utils/FeatureFlags/flags';
import { CurrentEmployeeContext } from 'src/common/contexts/CurrentEmployeeContext';
import { browserHistory } from 'src/common/utils/browserHistory';
import { CurrentProviderContextProvider } from 'common/contexts/CurrentProviderContext';
import { useInvoiceStatusCounts } from './hooks';

const InvoicesContainer = ({
  children,
  currentEmployee,
  showPayerInvoices,
  providerId,
  showInvoices,
  payerId,
  showInvoiceDisputeWorkflow,
}) => {
  const showArchive = useFeatureFlag('pays-1837-temp-invoices-archive');
  const { data } = useFindProviderRequired({ providerId });
  const isNetworkLead = get(data, 'provider_type') === 'network_lead';

  useEffect(() => {
    if (!showInvoices) {
      browserHistory.push('/');
    }
  }, [showInvoices]);

  const counts = useInvoiceStatusCounts(providerId, isNetworkLead, payerId, showPayerInvoices, !isEmpty(data));

  return (
    showInvoices && (
      <CurrentProviderContextProvider providerId={providerId}>
        <CurrentEmployeeContext.Provider value={currentEmployee}>
          <div className="flex antialiased min-h-full -mx-container-padding">
            <LeftNavigationBar className="space-y-2">
              <>
                {!isNetworkLead && !showPayerInvoices && (
                  <>
                    <DashboardNavigationLink
                      key="draft"
                      path="/invoices/draft"
                      label="Draft"
                      count={counts.draft}
                      className="text-13px"
                      data-testid="draft-invoice-bucket"
                    />
                    <DashboardNavigationLink
                      key="rejected"
                      path="/invoices/rejected"
                      label="Rejected"
                      count={counts.rejectedDWQ}
                      className="text-13px"
                      data-testid="rejected-invoice-bucket"
                    />
                  </>
                )}
                {(isNetworkLead || showPayerInvoices) && (
                  <DashboardNavigationLink
                    key="open"
                    path="/invoices/open"
                    label="Open"
                    count={counts.open}
                    className="text-13px"
                    data-testid="open-invoice-bucket"
                  />
                )}
                {showInvoiceDisputeWorkflow && (
                  <DashboardNavigationLink
                    key="disputed"
                    path="/invoices/disputed"
                    label="Disputed"
                    count={counts.disputed}
                    className="text-13px"
                    data-testid="disputed-invoice-bucket"
                  />
                )}
                {!isNetworkLead && !showPayerInvoices && (
                  <>
                    <DashboardNavigationLink
                      key="submitted"
                      path="/invoices/submitted"
                      label="Submitted"
                      className="text-13px"
                      data-testid="submitted-invoice-bucket"
                    />
                    <DashboardNavigationLink
                      key="accepted"
                      path="/invoices/accepted"
                      label="Accepted"
                      className="text-13px"
                      data-testid="accepted-invoice-bucket"
                    />
                  </>
                )}
                {(isNetworkLead || showPayerInvoices) && (
                  <DashboardNavigationLink
                    key="closed"
                    path="/invoices/closed"
                    label="Closed"
                    className="text-13px"
                    data-testid="closed-invoice-bucket"
                  />
                )}
                {showArchive && (
                  <DashboardNavigationLink
                    key="archived-invoices"
                    className="text-13px"
                    label="Archived"
                    path="/invoices/archive"
                    data-testid="archived-invoice-bucket"
                  />
                )}
                {/* All Invoices */}
                <DashboardNavigationLink
                  key="all-invoices"
                  className="text-13px"
                  label="All Invoices"
                  path="/invoices/all"
                  data-testid="all-invoices-bucket"
                />
              </>
            </LeftNavigationBar>
            <div className="mb-20 break-normal flex-grow overflow-hidden">
              {children}
            </div>
          </div>
        </CurrentEmployeeContext.Provider>
      </CurrentProviderContextProvider>
    )
  );
};

InvoicesContainer.propTypes = {
  children: PropTypes.node.isRequired,
  currentEmployee: PropTypes.object,
  showPayerInvoices: PropTypes.bool,
  providerId: PropTypes.string,
  showInvoices: PropTypes.bool,
  payerId: PropTypes.string,
  showInvoiceDisputeWorkflow: PropTypes.bool,
};

InvoicesContainer.defaultProps = {
  currentEmployee: {},
  providerId: '',
  showInvoices: false,
  showPayerInvoices: false,
  payerId: '',
  showInvoiceDisputeWorkflow: false,
};

function mapStateToProps(state) {
  const providerType = get(state, 'session.currentProvider.provider_type');
  const payerId = providerType === 'payer' ? get(state, 'session.currentProvider.payer.id') : '';

  return {
    payerId,
    currentEmployee: get(state, 'globalState.currentEmployee', ''),
    providerId: get(state, 'session.currentProvider.id', ''),
    showInvoices: hasInvoicesAccess(state),
    showPayerInvoices: hasPayerInvoicesRole(state),
    showInvoiceDisputeWorkflow: useInvoiceDisputeWorkflow(state),
  };
}

export default flow(
  FeatureFlagContainer,
  connect(mapStateToProps),
)(InvoicesContainer);
