/* eslint-disable */

// SurveyJS Eligibility Assessment JSON
// Reference https://app.uniteusdev.com/backoffice/forms/30b7ff88-c467-4969-b7a3-870d79091c9c/edit

const configuration = {
  "title": "Eligibility Assessment",
  "pages": [
   {
    "name": "page1",
    "elements": [
     {
      "type": "dropdown",
      "name": "qIsEnrolled",
      "title": "Is the client enrolled in Medicaid?",
      "description": "Check clients status on https://epaces.emedny.org/",
      "isRequired": true,
      "uuid": "34f2b223-1ad3-468f-a194-44605d6883c9",
      "choices": [
       {
        "value": "76822cca-4538-4cc0-854e-d71191f621ef",
        "text": "Yes",
        "uuid": "dd4866fd-630e-4168-921e-da539d6f999f"
       },
       {
        "value": "b763b1cb-bffb-4ee9-84a1-3a8b44ce5434",
        "text": "No",
        "uuid": "7a949cce-0039-4b14-9746-cde7a1608de0"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qSelectScreening",
      "visibleIf": "{qIsEnrolled} = '76822cca-4538-4cc0-854e-d71191f621ef'",
      "title": "Select screening to validate HRSN Social Needs",
      "isRequired": true,
      "uuid": "0544745d-b97d-4ecb-80b5-12300a31af7b",
      "choices": [
       {
        "value": "item1",
        "text": "Screening with Food and Housing",
        "uuid": "065a181b-dcfb-47aa-acc7-9aa5479764ee"
       },
       {
        "value": "item2",
        "text": "Screening with no needs",
        "uuid": "3254c1c4-c314-4de0-bb4b-e33ebf0b6ee8"
       },
       {
        "value": "item3",
        "text": "Screening with no needs",
        "uuid": "40dee5d3-9584-41ce-a70c-373aeeac79a7"
       }
      ]
     },
     {
      "type": "html",
      "name": "qIdentifiedNeedsHtml",
      "visibleIf": "{qSelectScreening} notempty and {qIsEnrolled} = '76822cca-4538-4cc0-854e-d71191f621ef'",
      "uuid": "8530a1f8-7452-4a31-9ed0-84a5cd5a2f97"
     },
     {
      "type": "dropdown-multiselect",
      "name": "qIdentifiedNeedsSelect",
      "visible": false,
      "title": "Identified Needs",
      "uuid": "accd379c-d325-42d7-97fb-fa42dba94ad0",
      "choices": [
       {
        "value": "housing",
        "text": "Housing",
        "uuid": "dbf458af-13e2-4cc4-9cfb-b495c5de7eda"
       },
       {
        "value": "transportation",
        "text": "Transportation",
        "uuid": "c59b5679-4aa3-4b77-8366-018c61aceb7b"
       },
       {
        "value": "food",
        "text": "Food",
        "uuid": "8124b5c7-2980-4170-b840-dc4d23ad44ec"
       },
       {
        "value": "utilities",
        "text": "Utilities",
        "uuid": "13bd57df-46ba-44de-af9e-a303197ab7ad"
       },
       {
        "value": "interpersonal_safety",
        "text": "Interpersonal Safety",
        "uuid": "3a74b2ea-8777-4fa0-a22b-a23884f75ef0"
       }
      ]
     },
     {
      "type": "dropdown-multiselect",
      "name": "qOtherNeeds",
      "visibleIf": "{qSelectScreening} notempty",
      "title": "Are there any needs not identified in the screenings that you'd like to add?",
      "description": "If there are additional needs, add them here and then ask the follow up questions in the next section.",
      "uuid": "0a5a18a3-54e2-43db-8ce3-706378b44c4d",
      "choices": [
       {
        "value": "utilities_id",
        "text": "Utilities",
        "uuid": "7e70fa09-e63c-472f-8406-be4f15f4fb48"
       },
       {
        "value": "transportation_id",
        "text": "Transportation",
        "uuid": "016d78cd-f23e-4df2-a31e-117ee5e0bf99"
       },
       {
        "value": "food_id",
        "text": "Food",
        "uuid": "046840f7-9ba1-4caa-a985-fc22bfdfdc25"
       },
       {
        "value": "housing_id",
        "text": "Housing",
        "uuid": "51bb6271-8c26-416d-882b-674e2e480ee3"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qReceiveHelp",
      "visibleIf": "{qSelectScreening} notempty",
      "title": "Does the client want to receive help for unmet needs?",
      "isRequired": true,
      "uuid": "7e2486cf-3529-4670-bb82-7183b9c3766e",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "a872c870-8970-410c-9531-618bac57b7b8"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "2a26789d-d6e9-4544-962c-0445263997f5"
       }
      ]
     },
     {
      "type": "checkbox",
      "name": "qReceivingServices",
      "visibleIf": "{qSelectScreening} notempty and {qReceiveHelp} = 'yes'",
      "title": "Is the client receiving any services for the unmet needs?",
      "description": "If YES, check which ones of these they are receiving. if NO, leave blank.",
      "uuid": "c58db662-cad3-4339-b1d2-ec9fb237bb29",
      "choices": [
       {
        "value": "item1",
        "text": "Supportive Housing",
        "uuid": "768e8335-6cc7-468e-ae9e-1d263d1becfb"
       },
       {
        "value": "item2",
        "text": "Healthy Homes",
        "uuid": "1b0f8d16-e5d9-42a6-9302-252a58532375"
       },
       {
        "value": "item3",
        "text": "Nutritional Counseling",
        "uuid": "9c19eac2-f0a0-459b-83c0-11e534126676"
       },
       {
        "value": "item4",
        "text": "Medically Tailored Meals (MTM)",
        "uuid": "c7b39fa2-4f01-4892-bb94-0f8ef277c23e"
       },
       {
        "value": "item13",
        "text": "In-lieu of Services (ILOS)",
        "uuid": "6bc3b28a-1e60-402b-a6d0-8c3f96662e9a"
       },
       {
        "value": "item5",
        "text": "Health Home Enrollment (under Enhanced Populations)",
        "uuid": "7ac49a8e-7c94-435f-984e-b2f15a049657"
       },
       {
        "value": "item6",
        "text": "Office for Persons with Developmental Disabilities (OPWDD) Waiver",
        "uuid": "9bba1884-59d1-4b24-92fd-ee01af79e988"
       },
       {
        "value": "item7",
        "text": "Traumatic Brain Injury (TBI) Waiver",
        "uuid": "0100a2df-1202-4654-9d3f-bae6f83e6c7f"
       },
       {
        "value": "item8",
        "text": "Nursing Home Transition Diversion (NHTD) Waiver",
        "uuid": "38ae5c88-6354-4dfa-82ee-74121a063a2e"
       },
       {
        "value": "item9",
        "text": "Children's Waiver",
        "uuid": "150acc2f-02ff-4e09-ba61-05fbc26336ae"
       },
       {
        "value": "item10",
        "text": "Medicaid Advantage Program (MAP)",
        "uuid": "e384fc0d-626f-47a0-9c46-a1d72be4e4d5"
       },
       {
        "value": "item11",
        "text": "Partial Capitation Managed Long-Term Care (MLTCP)",
        "uuid": "1db81275-36c4-4f23-bed7-ab006ab26d56"
       },
       {
        "value": "item12",
        "text": "Other",
        "uuid": "a3904e04-eeb8-4e41-a9c7-c7deb1eabc85"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qIsEnhancedPopulation",
      "visibleIf": "{qSelectScreening} notempty and {qReceiveHelp} = 'yes'",
      "title": "Is the client part of an enhanced population?",
      "isRequired": true,
      "uuid": "55fbcade-46de-4d18-8317-f929211c9368",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "ff9e54ce-b355-4f84-ad7d-1968a738e9a7"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "71d7f4ce-aacc-4451-a7e6-d7caa888b6f2"
       }
      ]
     },
     {
      "type": "checkbox",
      "name": "qSelectEnhanced",
      "visibleIf": "{qIsEnhancedPopulation} = 'yes'",
      "title": "Enhanced Populations",
      "description": "Available Options (select all that apply)",
      "isRequired": true,
      "uuid": "6a98c18a-a34f-4455-8946-4c086ba84f8b",
      "choices": [
       {
        "value": "item1",
        "text": "Medicaid High Utilizer (adults and children)",
        "uuid": "5b8ef62a-9c07-49db-9243-77ae718dd460"
       },
       {
        "value": "item2",
        "text": "Individuals Enrolled in a NYS Health Home",
        "uuid": "3b84f63b-36d4-4bd0-9644-3bc62d87ae82"
       },
       {
        "value": "item3",
        "text": "Individuals with Substance Use Disorders ",
        "uuid": "0d658fe3-66bf-4703-a750-5f9d0e2e126d"
       },
       {
        "value": "item4",
        "text": "Pregnant and Postpartum Persons",
        "uuid": "7be6aef0-efb3-42d8-91c9-0fee2b79a6e6"
       },
       {
        "value": "item5",
        "text": "High-Risk Children Under 18 Years Old​",
        "uuid": "6934926d-1138-404f-90be-085f740ef8ea"
       },
       {
        "value": "item6",
        "text": "Individuals with an Intellectual or Developmental Disability",
        "uuid": "859213fd-7dc1-409d-a968-7018b50215cb"
       },
       {
        "value": "item7",
        "text": "Individuals with Serious Mental Illness",
        "uuid": "dd3a8833-4031-4fef-ae87-5846acf41363"
       },
       {
        "value": "item9",
        "text": "Individuals with a Chronic Condition Released from Incarceration in the Past 90 Days",
        "uuid": "4b36a759-7537-498b-abb0-c43ac9962b8a"
       },
       {
        "value": "item10",
        "text": "High-Risk Children Under 6 Years Old",
        "uuid": "028d8034-572b-4d59-96fe-b64cc256e758"
       },
       {
        "value": "item11",
        "text": "Youth with Juvenile Justice Involvement, in Foster Care Youth or Under Kinship Care​",
        "uuid": "69c5ba3b-2b66-4a04-bbc4-fa8b2e589e71"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qDoesClientHaveClinicalConditions",
      "visible": false,
      "visibleIf": "{qIsEnhancedPopulation} = 'yes'",
      "title": "Does the client have clinical conditions?",
      "isRequired": true,
      "uuid": "127ba64f-02b3-434d-a761-c4afab9347d7",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "6c6fab7d-a817-4b19-aa79-d36b17492daf"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "1d7fca0d-ca39-4792-b008-a28bb5aa6b1c"
       }
      ]
     },
     {
      "type": "checkbox",
      "name": "qClinicalConditions",
      "visible": false,
      "visibleIf": "{qDoesClientHaveClinicalConditions} = 'yes'",
      "title": "Clinical conditions",
      "isRequired": true,
      "uuid": "bbf8c67c-2fd1-4d2c-ae4c-4f0887c0d0d7",
      "choices": [
       {
        "value": "item1",
        "text": "Chronic Conditions",
        "uuid": "948f30d6-60d4-4a75-a616-64504aef9aef"
       },
       {
        "value": "item2",
        "text": "Uncontrolled Asthma",
        "uuid": "2f44cac6-1478-45cc-9444-cf4164039665"
       },
       {
        "value": "item3",
        "text": "Enteral or Parenteral Nutrition Therapy",
        "uuid": "49f2bf52-03f5-47b7-a3fc-24197e2fb456"
       },
       {
        "value": "item4",
        "text": "Institutional Setting",
        "uuid": "8bfb4ca7-8eab-40d7-bace-3b51e6993db8"
       },
       {
        "value": "item5",
        "text": "Heat-Related Illness",
        "uuid": "7e800351-2913-493c-88b1-262274ad98d1"
       },
       {
        "value": "item6",
        "text": "Cold-Related Illness",
        "uuid": "60c6ed6d-3124-4566-9f12-8894fb48e019"
       },
       {
        "value": "item7",
        "text": "Thermoregulation Interference",
        "uuid": "6cc7e022-af8e-41f7-8815-bdad76a4cf6e"
       },
       {
        "value": "item8",
        "text": "Overweight, obese, or underweight",
        "uuid": "2c827f96-d7b5-45fc-8100-6b709dfe8313"
       }
      ]
     },
     {
      "type": "html",
      "name": "qNeedSpecificQuestionsHeader",
      "visible": false,
      "visibleIf": "{qIsEnhancedPopulation} = 'yes'",
      "uuid": "db3af5dc-f899-4a50-babf-757be492e2ce",
      "html": "<h2 class=\"pb-5\">Need-Specific Questions</h2>"
     },
     {
      "type": "html",
      "name": "qTransportationNeed1",
      "visible": false,
      "uuid": "736a11b8-4a3a-4b16-a0c6-1d27c2a889aa",
      "html": "<h3 class=\"pb-5\">Transportation</h3>"
     },
     {
      "type": "dropdown-multiselect",
      "name": "qTransportationNeed2",
      "visible": false,
      "title": "What type of transportation assistance does the client need?",
      "uuid": "0f31e1cc-b180-488f-8a09-dad37283e2d1",
      "choices": [
       {
        "value": "Item 1",
        "text": "551761000000000 - Transportation insecurity due to unaffordable transportation",
        "uuid": "9764e058-b1f1-4c88-af71-dc5a1c3059c1"
       },
       {
        "value": "Item 2",
        "text": "551741000124108 - Transportation insecurity due to no access to vehicle",
        "uuid": "1ce2defa-8145-4b4a-9f63-5f1cf58a4e87"
       },
       {
        "value": "Item 3",
        "text": "713458007 - Lack of access to transportation",
        "uuid": "2cdc924e-7388-43ed-9c7f-a7fbb4a8d589"
       },
       {
        "value": "Item 4",
        "text": "551721000124101 - Transportation insecurity due to no driver's license",
        "uuid": "9a50b82c-2afe-49b6-8a0c-39afb60d609b"
       },
       {
        "value": "Item 5",
        "text": "551711000124109 - Transportation insecurity due to excessive travel time to destination",
        "uuid": "94ab3b59-2b7a-442b-aa18-cf44edffad8d"
       },
       {
        "value": "Item 6",
        "text": "160696009 - Transport distance too great",
        "uuid": "ca8c15e1-a396-4b01-9d9c-39827a6ffddd"
       },
       {
        "value": "Item 7",
        "text": "611151000124107 - Transportation insecurity due to route not serviced by public transportation",
        "uuid": "e6e3c313-80a5-4d4d-9887-5575b6e1b6b3"
       },
       {
        "value": "Item 8",
        "text": "551701000000000 - Transportation insecurity due to unsafe transportation environment",
        "uuid": "934d0232-586b-4282-b2a4-48b7fedc3c2d"
       },
       {
        "value": "Item 9",
        "text": "611161000124109 - Transportation insecurity limiting access to food",
        "uuid": "8fda024f-8f00-4cca-8d4f-11dfdea26f10"
       },
       {
        "value": "Item 10",
        "text": "551731000124103 - Inability to access healthcare due to transportation insecurity",
        "uuid": "9c66072d-7099-4455-8414-49a3ea3762cd"
       },
       {
        "value": "Item 11",
        "text": "551751000124105 - Inability to access community resources due to transportation insecurity",
        "uuid": "dc453219-0b95-4031-9331-35a7d2a31fef"
       }
      ]
     },
     {
      "type": "dropdown-multiselect",
      "name": "qTransportationNeed3",
      "visible": false,
      "title": "For what activities does the client need help with transportation?",
      "uuid": "452aa9d2-157c-49ad-9eaa-f86c74332638",
      "choices": [
       {
        "value": "education",
        "text": "Education",
        "uuid": "93294dab-46e4-4b6c-99d4-2e58581aa35e"
       },
       {
        "value": "employment",
        "text": "Employment",
        "uuid": "9ed1bb42-aa25-4cb9-86fa-9111ab492419"
       },
       {
        "value": "family",
        "text": "Family or Social Activities",
        "uuid": "d6a211f1-981b-4830-a527-e8ac4495c983"
       },
       {
        "value": "medical",
        "text": "Medical Appointments",
        "uuid": "25b80ede-5225-44ae-bed7-7224c277cb17"
       },
       {
        "value": "recreational",
        "text": "Recreational Activities",
        "uuid": "5b5b817e-aed7-4db7-8a94-6de86420abb1"
       },
       {
        "value": "religious",
        "text": "Religious Services",
        "uuid": "2cc1f938-6898-45a1-9ff2-0d4e4143f748"
       },
       {
        "value": "shopping",
        "text": "Shopping",
        "uuid": "ea0f09fd-76be-4328-b296-80b7ae1a7129"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qTransportationNeed4",
      "visible": false,
      "title": "Does the client already receive any transportation services?",
      "uuid": "6b398547-ae14-44ba-a041-361a03a1217a",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "4fae0fd8-c162-4d97-b6e3-e9abe9eccef9"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "b275be11-57ee-4be1-aae6-62d60d68fddd"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qTransportationNeed5",
      "visible": false,
      "title": "Does the client want to receive services for Transportation needs?",
      "isRequired": true,
      "uuid": "869cdeb2-8743-4956-8edd-e4c8357bfb9d",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "ed686bbf-5821-4801-8658-dca057e1f158"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "a9de8c70-3e3b-4f30-9420-0f5d429eeb09"
       }
      ]
     },
     {
      "type": "html",
      "name": "qFoodNeed1",
      "visible": false,
      "uuid": "2423c888-41ae-4462-911f-5f2225d5f171",
      "html": "<h3 class=\"pb-5\">Food</h3>"
     },
     {
      "type": "dropdown-multiselect",
      "name": "qFoodNeed2",
      "visible": false,
      "title": "What type of food assistance does the client need?",
      "uuid": "80e8cc72-9e08-4b4f-83d6-8afcdf294bdf",
      "choices": [
       {
        "value": "Item 1",
        "text": "Supplemental Nutrition Assistance Program (SNAP)",
        "uuid": "d8463031-ba3c-4140-a8e8-940be91913a2"
       },
       {
        "value": "Item 2",
        "text": "Special Supplemental Nutrition Program for Women, Infants, and Children (WIC)",
        "uuid": "15161847-93c0-4713-8dfd-e66efbb78b68"
       },
       {
        "value": "Item 3",
        "text": "School meals and food programs for children",
        "uuid": "9c2b62d4-2ca8-441e-8bb4-39aceab24c80"
       },
       {
        "value": "Item 4",
        "text": "Food assistance programs for older adults or seniors",
        "uuid": "81ce9956-a49f-430d-8c1e-759ffb36bf6e"
       },
       {
        "value": "Item 5",
        "text": "Emergency Food Assistance Program",
        "uuid": "0b0086fb-9cbe-4cca-a29a-ef14dabdc82f"
       },
       {
        "value": "Item 6",
        "text": "Food Prescription or Tailored Meal Program",
        "uuid": "e0c389d8-c32a-4826-840a-e0d701d777cc"
       },
       {
        "value": "Item 7",
        "text": "Food Distribution Program on Indian Reservations",
        "uuid": "e1ac6e34-33c3-437a-881a-504069b0d243"
       },
       {
        "value": "Item 8",
        "text": "Temporary or seasonal meal program",
        "uuid": "2d5b3ae5-4a90-4f9d-a334-cc1654fc1296"
       },
       {
        "value": "Item 9",
        "text": "Cash transfers and vouchers",
        "uuid": "f1740e34-59c2-4f4c-a324-41c40fd49c8f"
       }
      ]
     },
     {
      "type": "dropdown-multiselect",
      "name": "qFoodNeed3",
      "visible": false,
      "title": "Is the client enrolled in any of the following?",
      "description": "If the Client is enrolled in Managed Care and Eligibile for SNAP or WIC, the Navigator can assist with an application",
      "uuid": "0d58fff6-7077-43d0-8a7e-41adf575194c",
      "choices": [
       {
        "value": "Item 1",
        "text": "SNAP",
        "uuid": "aca9e6a2-c13d-41e2-9a1d-adf4d69cfb74"
       },
       {
        "value": "Item 2",
        "text": "WIC",
        "uuid": "395b784f-4c71-40a5-94ce-1720ea25c50e"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qFoodNeed4",
      "visible": false,
      "title": "How many people in the client's household need food assistance?",
      "uuid": "624a5875-8824-426a-8131-4055d6770ea8",
      "choices": [
       {
        "value": "item1",
        "text": "1",
        "uuid": "b9359fcc-f552-4e3d-b6e8-953192f2c6ca"
       },
       {
        "value": "item2",
        "text": "2",
        "uuid": "ad37f3b5-0edf-42be-b529-4068140a5bf2"
       },
       {
        "value": "item3",
        "text": "3",
        "uuid": "ec34c405-7d04-4ad5-9268-e964f79e28f3"
       },
       {
        "value": "item4",
        "text": "4",
        "uuid": "49e3599d-774c-4e13-b11e-a5a225c35821"
       },
       {
        "value": "item5",
        "text": "5",
        "uuid": "42f95f01-29db-4d78-a40b-cb280b208e39"
       },
       {
        "value": "item6",
        "text": "6",
        "uuid": "4a0b80d8-06ee-4d7d-b854-f1ddde48ea44"
       },
       {
        "value": "item7",
        "text": "7",
        "uuid": "de0e5e71-f927-41e0-88b1-6e874ecf4d63"
       },
       {
        "value": "item8",
        "text": "8",
        "uuid": "8fdf3c7f-1d16-4aea-ac3a-b692201b2843"
       },
       {
        "value": "item9",
        "text": "9",
        "uuid": "10162787-5700-468e-9348-420577408525"
       },
       {
        "value": "item10",
        "text": "10",
        "uuid": "b6f9d851-6f2f-4df2-bed8-6a8c9f9a7b87"
       },
       {
        "value": "item11",
        "text": "11",
        "uuid": "83b3978d-dc3d-43dc-883e-40217977a129"
       },
       {
        "value": "item12",
        "text": "12",
        "uuid": "617b1984-592f-4f2e-9f0d-132a3581b61d"
       },
       {
        "value": "item13",
        "text": "13",
        "uuid": "e11b7db9-94e1-4b6c-8eab-fbe67ab59a7a"
       },
       {
        "value": "item14",
        "text": "14",
        "uuid": "b1ff902b-97fd-4a51-bdf4-7fa3012a83a1"
       },
       {
        "value": "item15",
        "text": "15",
        "uuid": "1d73096e-8e20-4c17-886e-0cb3c7b2fb14"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qFoodNeed5",
      "visible": false,
      "title": "Does the client have a valid state-issued ID?",
      "uuid": "8b6fa04a-dc18-40a6-a19b-553a0148103f",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "080ccb74-c016-4af2-b6b2-5b280f3a3acc"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "5d6ba8cc-943d-4be6-a22e-d29007553901"
       }
      ]
     },
     {
      "type": "dropdown-multiselect",
      "name": "qFoodNeed6",
      "visible": false,
      "title": "On which days would the client like to have meals delivered?",
      "description": "Select all that apply.",
      "uuid": "b53565ea-cc65-4475-92be-65e30e0b5a7d",
      "choices": [
       {
        "value": "Item 1",
        "text": "Monday",
        "uuid": "a9441533-c3c6-4782-8f49-5de8a02e0f98"
       },
       {
        "value": "Item 2",
        "text": "Tuesday",
        "uuid": "a448bce1-a8cf-4a01-b288-7db49b2bc175"
       },
       {
        "value": "Item 3",
        "text": "Wednesday",
        "uuid": "9f8255dc-6c8d-46a7-9626-4fbfc49f3071"
       },
       {
        "value": "Item 4",
        "text": "Thursday",
        "uuid": "9540ca21-8126-4352-b5a6-e5b61044ce02"
       },
       {
        "value": "Item 5",
        "text": "Friday",
        "uuid": "30ebc2c8-b3fa-4b62-9431-0afb61bcd6c9"
       },
       {
        "value": "Item 6",
        "text": "Saturday",
        "uuid": "96bee632-55eb-493e-9485-fee70f424609"
       },
       {
        "value": "Item 7",
        "text": "Sunday",
        "uuid": "3c30ace2-7a05-4a40-93ec-f9b4a21dc506"
       }
      ]
     },
     {
      "type": "dropdown-multiselect",
      "name": "qFoodNeed7",
      "visible": false,
      "title": "Does the client have any of the following refrigeration needs?",
      "uuid": "af0fe730-921d-4990-8546-ab9810425be6",
      "choices": [
       {
        "value": "Item 1",
        "text": "Medication requiring refrigeration",
        "uuid": "5c51d592-3fff-489d-b3a1-54c52a3dadd4"
       },
       {
        "value": "Item 2",
        "text": "Breast milk requiring refrigeration",
        "uuid": "9b44f962-d194-45bd-bc3b-fc4d3d21f3da"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qFoodNeed8",
      "visible": false,
      "title": "Does the client have any dietary restrictions or food allergies?",
      "uuid": "f7112655-09b4-4caf-90d4-fe9e89f534ad",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "27fcb360-0ba7-43e7-a396-5fa05c0dd5d3"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "78c51204-d0d0-47e8-a8ba-3160d2d43ae2"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qFoodNeed11",
      "visible": false,
      "title": "Is the client currently receiving nutritional counseling or education?",
      "uuid": "2fd41b55-b818-49e3-a520-8ed9c6e99d15",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "986199d1-8c1e-4691-a212-098d7a7d1155"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "c486b92c-c23e-4db3-8439-8d9cbb62708a"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qFoodNeed12",
      "visible": false,
      "title": "Is the client receiving any other nutrition services?",
      "uuid": "9af4a229-d460-4034-be60-b1d347099ed7",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "322d02f9-5ebd-4896-a1a3-9844e8fd7610"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "87a530e9-51f5-48c2-8f1d-52dac609a251"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qFoodNeed13",
      "visible": false,
      "title": "Does the client have the ability to store food, such as a refrigerator?",
      "uuid": "87974721-1f05-4b78-a45a-491177814190",
      "choices": [
       {
        "value": "item1",
        "text": "Yes",
        "uuid": "2c1e8a31-1011-4646-b3e7-62ab4a466d47"
       },
       {
        "value": "item2",
        "text": "No",
        "uuid": "19f60480-ee8c-4db3-b61b-3b36f3c2f340"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qFoodNeed9",
      "visible": false,
      "title": "Is the client receiving any home-delivered meals or food services?",
      "uuid": "bc5c1e7d-37cf-42dd-82c5-3e15604c880c",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "7628121a-c7db-4344-b6d1-19b3789092c1"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "d5e07284-edaa-430e-8189-7216a708a98a"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qFoodNeed10",
      "visible": false,
      "title": "Does the client want to receive services for Food needs?",
      "isRequired": true,
      "uuid": "40c59f88-4303-4c89-9e09-ae5a292391e1",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "158a1c80-2e8d-4235-bacf-c080fe3b0698"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "8e884194-5e9a-4e07-9349-cb11406039d5"
       }
      ]
     },
     {
      "type": "html",
      "name": "qHousingNeed1",
      "visible": false,
      "uuid": "58c4d12f-7acf-461f-95f5-b216514f4bce",
      "html": "<h3 class=\"pb-5\">Housing</h3>"
     },
     {
      "type": "dropdown-multiselect",
      "name": "qHousingNeed2",
      "visible": false,
      "title": "What type of housing assistance does the client need?",
      "description": "Select all that apply.",
      "uuid": "22b68b21-f1c7-4ea3-90ce-85efcc23b95f",
      "choices": [
       {
        "value": "Item 1",
        "text": "611551000124102​ - Homelessness, living in a​ shared accommodation",
        "uuid": "7a13fd70-d8de-4cf7-baa6-53875182683f"
       },
       {
        "value": "Item 2",
        "text": "611131000124100​ - Sheltered homelessness",
        "uuid": "d84009f0-d086-4634-942c-ff04a4861464"
       },
       {
        "value": "Item 3",
        "text": "611141000124105 - ​Unsheltered​ homelessness",
        "uuid": "4938bfc7-52fc-495e-9a98-f602b06f3735"
       },
       {
        "value": "Item 4",
        "text": "611181000124104​ - Unsheltered​ homelessness, sleeping in​ safe environment",
        "uuid": "f54efb4b-ff94-4cd8-bb2e-aa7d0ccf64e6"
       },
       {
        "value": "Item 5",
        "text": "1162585007 - Infestation of place of residence",
        "uuid": "1dd3f232-2247-4e02-b703-528d584c817e"
       },
       {
        "value": "Item 6",
        "text": "224255009 - Mold growth in home",
        "uuid": "87212ca1-557f-4852-be67-bcf7e58cb595"
       },
       {
        "value": "Item 7",
        "text": "1197631001 - Lead in residence",
        "uuid": "59b898b7-2006-4654-b72a-2da5701ebf7e"
       },
       {
        "value": "Item 8",
        "text": "105535008 - Lack of heat in house",
        "uuid": "6e49945b-a005-4c36-9e59-a9248a98d220"
       },
       {
        "value": "Item 9",
        "text": "1197634009 - Inadequate food preparation equipment in residence",
        "uuid": "ee4c798e-dc23-4534-b49b-813d115c92f9"
       },
       {
        "value": "Item 10",
        "text": "1197640002 - Inadequate smoke detection equipment in residence",
        "uuid": "7fb18a29-2c08-44db-98b7-17ca75d5a573"
       },
       {
        "value": "Item 11",
        "text": "105531004 - Housing unsatisfactory",
        "uuid": "5b3845cc-7072-4f54-97a8-37ede77394dc"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qHousingNeed3",
      "visible": false,
      "title": "Is the client's household currently working with a housing provider (Housing Program Recipient)?",
      "uuid": "02d9018c-2af0-4fbc-b80e-25bf07a539ae",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "fade7146-2cc7-4128-b616-5d410cd917e0"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "270c048d-4e0d-4565-96fa-a6c11fde85f6"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qHousingNeed4",
      "visible": false,
      "title": "Is the client on any housing waitlists (i.e. HUD Section 8, local housing authority, or other voucher programs)?",
      "uuid": "932c38be-d502-4dba-ac83-4457f88ffa19",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "f5dd0f44-43d9-45c9-864f-a4f184ec7fc5"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "cbd2bbd6-6e4c-4933-ac86-78f832dfee3c"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qHousingNeed5",
      "visible": false,
      "title": "How many people are in the client’s household?",
      "uuid": "69951fb4-4178-452b-b27d-5264746f703f",
      "choices": [
       {
        "value": "item1",
        "text": "1",
        "uuid": "61b95486-af63-4552-bad2-58ca6a7eea89"
       },
       {
        "value": "item2",
        "text": "2",
        "uuid": "f04b5530-7c96-412c-97df-4e6fce0c1c52"
       },
       {
        "value": "item3",
        "text": "3",
        "uuid": "2d988446-a2e9-409a-9501-a799ac9b5460"
       },
       {
        "value": "item4",
        "text": "4",
        "uuid": "35646a16-8fbf-4428-9394-6a5d852269b4"
       },
       {
        "value": "item5",
        "text": "5",
        "uuid": "4e46fc2f-de6e-4ade-87de-396a9568f618"
       },
       {
        "value": "item6",
        "text": "6",
        "uuid": "2dcb1b6b-fab5-4a29-9b65-e83271146103"
       },
       {
        "value": "item7",
        "text": "7",
        "uuid": "14a9c004-4d3c-491e-82d0-5c6b0feeaf32"
       },
       {
        "value": "item8",
        "text": "8",
        "uuid": "e93c6921-523b-45f9-a772-e9d9787be62a"
       },
       {
        "value": "item9",
        "text": "9",
        "uuid": "37f8e0af-53a7-4065-a1ab-a8b68a0dd932"
       },
       {
        "value": "item10",
        "text": "10",
        "uuid": "d36db90a-4393-46ab-9287-f41cac63525e"
       },
       {
        "value": "item11",
        "text": "11",
        "uuid": "966477d3-8780-4307-a55a-aadc1591cc60"
       },
       {
        "value": "item12",
        "text": "12",
        "uuid": "831a5acb-51e8-4e20-ba21-bb6dae5e2ccb"
       },
       {
        "value": "item13",
        "text": "13",
        "uuid": "a4935192-51ed-42a1-8002-3baa06b39b51"
       },
       {
        "value": "item14",
        "text": "14",
        "uuid": "9bf39415-6622-4082-ab82-f67f6cd42006"
       },
       {
        "value": "item15",
        "text": "15",
        "uuid": "428378e1-3607-43c4-a27d-b74d2949cb7b"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qHousingNeed6",
      "visible": false,
      "title": "Does the client want to receive services for Housing needs?",
      "isRequired": true,
      "uuid": "f800497d-d9a9-4c33-82d5-8225241e50c1",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "8ebd846c-1902-4cc7-ad18-20c2a3011280"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "b1519ae4-7310-40e8-8624-12e935dd708d"
       }
      ]
     },
     {
      "type": "html",
      "name": "qUtilitiesNeed1",
      "visible": false,
      "uuid": "46fa27cd-3362-4b42-81a7-a740aab169f0",
      "html": "<h3 class=\"pb-5\">Utilities</h3>"
     },
     {
      "type": "dropdown-multiselect",
      "name": "qUtilitiesNeed2",
      "visible": false,
      "title": "What type of utilities assistance does the client need?",
      "description": "Select all that apply.",
      "uuid": "4c21a1e4-cc5c-4b8d-a07c-75fadbd4cd27",
      "choices": [
       {
        "value": "Item 1",
        "text": "105536009 - Living in housing without electricity",
        "uuid": "5b99d80f-8d66-46fd-bb17-31a23316675a"
       },
       {
        "value": "Item 2",
        "text": "5491000175103 - Lack of running water in house",
        "uuid": "9de63f06-759b-416f-b9eb-fd51dec0cd44"
       },
       {
        "value": "Item 3",
        "text": "671391000124106 - Unable to obtain electricity in residence due to limited financial resources",
        "uuid": "4cfc433e-753a-4c13-aa34-059311ececbd"
       },
       {
        "value": "Item 4",
        "text": "671371000124105 - Unable to heat residence due to limited financial resources",
        "uuid": "03843ff2-3286-4393-b93a-445660133f73"
       },
       {
        "value": "Item 5",
        "text": "671401000124108 - Unable to cool residence due to limited financial resources",
        "uuid": "d01fb1c2-fbdd-426b-a646-4ba6a6a6501e"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qUtilitiesNeed3",
      "visible": false,
      "title": "Does the client want to receive services for Utilities needs?",
      "isRequired": true,
      "uuid": "f541e8a1-5d53-4b2e-a7cc-9fe4f2fd405f",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "d1ae5caa-afe3-4f04-8f75-94ea03a367f9"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "f2a697cc-9965-45fb-8fd9-33a3905ac8f3"
       }
      ]
     },
     {
      "type": "html",
      "name": "qSupplementalQuestions1",
      "visibleIf": "{qIsEnhancedPopulation} = 'yes'",
      "uuid": "76e73e5a-500d-4423-b609-6084a80d00af",
      "html": "<h2 class=\"pb-5\">Supplemental Questions</h2>"
     },
     {
      "type": "dropdown",
      "name": "qSupplementalQuestions2",
      "visibleIf": "{qIsEnhancedPopulation} = 'yes'",
      "title": "Is the client enrolled in the TANF program?",
      "isRequired": true,
      "uuid": "3336940e-9d6b-4d35-bb33-5ca0497f49f5",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "2c76c178-ccf7-4a44-bcd5-9904def9d5a3"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "f91f0ed7-5753-4343-ac85-29c3a70cf4db"
       }
      ]
     },
     {
      "type": "dropdown-multiselect",
      "name": "qSupplementalQuestions3",
      "visibleIf": "{qIsEnhancedPopulation} = 'yes'",
      "title": "Does the client have any of the following needs?",
      "description": "Select all that apply.",
      "isRequired": true,
      "uuid": "3c70f598-7aaa-4e56-9d81-af57f94468ff",
      "choices": [
       {
        "value": "Item 1",
        "text": "Activities of Daily Living (ADLs) & Instrumental Activities of Daily Living (IADLs) needs",
        "uuid": "2cfdebd7-b8a7-44b6-81e5-05131c4511ce"
       },
       {
        "value": "Item 2",
        "text": "Medical Respite",
        "uuid": "9accd094-d8ef-4e25-a96f-f5f912ac1927"
       },
       {
        "value": "Item 3",
        "text": "Childcare Assistance",
        "uuid": "f94785a0-bd04-4793-b13a-3f6a20b34408"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qSupplementalQuestions4",
      "visibleIf": "{qIsEnhancedPopulation} = 'yes'",
      "title": "Does the client have serious difficulty walking or climbing stairs? (5 years old or older)",
      "enableIf": "{qIsEnhancedPopulation} = 'item1'",
      "isRequired": true,
      "uuid": "4f8ed83a-f0e0-4b68-b836-2295b8217daa",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "ba004130-c053-4b28-9656-4aeff037c98f"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "e1273713-70af-4ab2-9d8f-fae428c15f3e"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qSupplementalQuestions5",
      "visibleIf": "{qIsEnhancedPopulation} = 'yes'",
      "title": "Does the client have difficulty dressing or bathing? (5 years old or older)",
      "isRequired": true,
      "uuid": "83f3e321-7539-4c8c-83d5-91fa3e89b902",
      "choices": [
       {
        "value": "item1",
        "text": "Yes",
        "uuid": "f3c98037-ca05-40fc-bfa0-932224997619"
       },
       {
        "value": "item2",
        "text": "No",
        "uuid": "6c14e91c-3820-45e3-a341-33571480a566"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qSupplementalQuestions6",
      "visible": false,
      "visibleIf": "{qIsEnhancedPopulation} = 'yes'\n\n",
      "title": "What is the client's current employment status?",
      "uuid": "5f0d22ce-0bc8-4f13-96cc-3e0c5ed7daec",
      "choices": [
       {
        "value": "employed",
        "text": "Employed",
        "uuid": "ed622fee-f3a9-4c6e-8f70-aabcc98b5889"
       },
       {
        "value": "unemployed",
        "text": "Unemployed",
        "uuid": "7e3b253f-9a3b-4fd5-8c19-43ab399fb312"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qSupplementalQuestions7",
      "visible": false,
      "visibleIf": "{qIsEnhancedPopulation} = 'yes'\n\n\n\n",
      "title": "Does the client have income from any source?",
      "uuid": "6a0ae177-3a79-49e4-ac1a-c782cde47d39",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "b4fb7c6a-2ab8-4411-8692-5fff88f06103"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "89f890ea-f270-4d18-b8ec-18abee852c19"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qSupplementalQuestions8",
      "visible": false,
      "visibleIf": "{qIsEnhancedPopulation} = 'yes'\n",
      "title": "Does the client need assistance with childcare?",
      "uuid": "870f80c4-b653-4e93-a7f5-cffbe80fc238",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "09919179-f3e6-4479-be77-4f0a61ab22ac"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "009af573-9694-4d7b-9a01-750e08edb15c"
       }
      ]
     },
     {
      "type": "html",
      "name": "qNotEligible",
      "visible": false,
      "uuid": "b3780b6b-7d6d-44c7-a3b2-d2dc09e99c36",
      "html": "<div><div class=\"my-5 align-middle inline-block mr-4\"><svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M16 8C16 3.6129 12.3871 0 8 0C3.58065 0 0 3.6129 0 8C0 12.4194 3.58065 16 8 16C12.3871 16 16 12.4194 16 8ZM8 9.6129C8.80645 9.6129 9.48387 10.2903 9.48387 11.0968C9.48387 11.9355 8.80645 12.5806 8 12.5806C7.16129 12.5806 6.51613 11.9355 6.51613 11.0968C6.51613 10.2903 7.16129 9.6129 8 9.6129ZM6.58065 4.29032C6.54839 4.06452 6.74194 3.87097 6.96774 3.87097H9C9.22581 3.87097 9.41936 4.06452 9.3871 4.29032L9.16129 8.67742C9.12903 8.90323 8.96774 9.03226 8.77419 9.03226H7.19355C7 9.03226 6.83871 8.90323 6.80645 8.67742L6.58065 4.29032Z\" fill=\"#182A44\"></path></svg></div><div class=\"font-bold italic align-middle inline-block\"><p>The client is not eligible for enhanced services per NY1115 Waiver guidelines.</p><p>Submit the assessment and direct the client to existing programs in your network.</p></div></div>"
     }
    ]
   }
  ]
 }

/* eslint-disable */
export default {
    configuration: {
      ...configuration,
    }
  };



