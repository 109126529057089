import isServiceTypeInProgram from 'src/components/Referrals/ReferralDetail/utils/isServiceTypeInProgram';
import returnNoServiceTypeInProgramMessage from 'src/components/Referrals/ReferralDetail/utils/returnNoServiceTypeInProgramMessage';
import { HOLD_MODAL_DIALOG } from '../../constants';
import canChangeHoldReason from './canChangeHoldReason';

const changeHoldReason = async ({
  referral,
  caseReferrals,
  groupId,
  user,
  openModal,
}) => {
  try {
    const sentReferrals = caseReferrals.filter((r) => r.state === 'sent');
    if (!canChangeHoldReason({ user, referral, groupId }) || sentReferrals.length > 1) {
      return null;
    }

    return {
      label: 'Change Hold Reason',
      value: 'changeHoldReason',
      className: 'take-action-hold-for-review',
      action: () => openModal(HOLD_MODAL_DIALOG),
      ...(!isServiceTypeInProgram(referral) ? {
        disabled: true,
        customProperties: { message: returnNoServiceTypeInProgramMessage({ action: 'changeHoldReason' }) },
      } : {}),
    };
  } catch (error) {
    return error;
  }
};

export default changeHoldReason;
