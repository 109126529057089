import PropTypes from 'prop-types';
import React from 'react';
import { isNumber } from 'lodash';
import { Link } from 'react-router';
import cx from 'classnames';
import { Badge } from '@unite-us/ui';

const DashboardNavigationLink = ({
  path,
  label,
  className,
  count,
  badgeAriaLabel,
  'data-testid': dataTestId,
}) => (
  <Link
    activeClassName="bg-dark-grey"
    to={path}
    className="flex items-center py-2 px-3 rounded-md hover:bg-dark-grey focus:bg-dark-grey"
    data-testid={dataTestId}
  >
    <div
      className={cx(
        'w-full px-3 leading-6 font-medium-font text-white flex items-center justify-between',
        className,
      )}
    >
      <div>{label}</div>
      {isNumber(count) &&
        (
        <Badge
          badgeAriaLabel={badgeAriaLabel}
          text={count}
          style={{
            paddingTop: 3,
            height: 18,
            minWidth: 25,
            fontSize: 11,
            backgroundColor: '#D0021B',
          }}
        />
        )}
    </div>
  </Link>
);

DashboardNavigationLink.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number,
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  badgeAriaLabel: PropTypes.string,
  'data-testid': PropTypes.string,
};

DashboardNavigationLink.defaultProps = {
  className: '',
  count: null,
  badgeAriaLabel: '',
  'data-testid': undefined,
};

export default DashboardNavigationLink;
