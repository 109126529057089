import { every, isEmpty } from 'lodash';
import {
  isReferralsAdminAndAbove,
  isReferralsUserAndAbove,
} from 'src/components/User/utils';

// ability to Forward a referral
function send({
  user,
  groupId,
  referral,
  caseReferrals,
  goToReferralSend,
}) {
  const inFlightReferrals = caseReferrals.filter((r) => ['sent', 'in_review'].includes(r.state));
  let checks = [];

  const {
    state: referralState,
    case: {
      state: caseState,
      outcome: caseOutcome,
      service_authorization,
    },
  } = referral;

  if (caseState === 'draft') {
    return null;
  }

  let referralType = 'bystander';
  if (referral.receiving_provider.id === groupId) {
    referralType = 'inbound';
  }
  if (referral.sending_provider.id === groupId) {
    referralType = 'outbound';
  }
  if (referralType === 'inbound') {
    checks = [
      isReferralsAdminAndAbove(user, groupId),
      ['sent', 'in_review'].includes(referralState),
      ['referred'].includes(caseState),
      inFlightReferrals.length <= 1,
      referral.sending_provider.sensitive === false, // sensitive referrals can't be forwarded
      referral.receiving_provider.sensitive === false, // sensitive referrals can't be forwarded
      caseOutcome === null,
      isEmpty(service_authorization),
    ];
  }

  if (referralType === 'outbound') {
    checks = [
      isReferralsUserAndAbove(user, groupId),
      ['declined', 'recalled'].includes(referralState),
      ['deferred'].includes(caseState), // other referrals could still be in flight
      referral.sending_provider.sensitive === false, // sensitive referrals can't be forwarded
      referral.receiving_provider.sensitive === false, // sensitive referrals can't be forwarded
      caseOutcome === null,
      isEmpty(service_authorization),
    ];
  }

  if (referralType === 'bystander') { return null; }
  if (!every(checks)) { return null; }

  return {
    label: 'Send',
    value: 'send',
    className: 'take-action-send',
    action: goToReferralSend,
  };
}

export default send;
