import React from 'react';
import PropTypes from 'prop-types';
import { isNil, capitalize } from 'lodash';
import { Badge } from '@unite-us/app-components';

const StatusBadge = ({ status }) => {
  const commonClasses = 'leading-4 w-20';
  const statusText = capitalize(status);
  if (isNil(status)) {
    return <Badge text={statusText} className={commonClasses} color="#F4D316" />;
  }

  if (status.toLowerCase() === 'complete') {
    // tw color 'dark-green'
    return <Badge text={statusText} className={commonClasses} color="#078B00" />;
  }
  if (status.toLowerCase() === 'draft') {
    // tw color 'canary-yellow'
    return <Badge text={statusText} className={commonClasses} color="#F4D316" />;
  }
  if (status.toLowerCase() === 'declined') {
    // tw color 'red'
    return <Badge text={statusText} className={commonClasses} color="#D0021B" />;
  }

  return null;
};

StatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusBadge;
