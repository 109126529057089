import React from 'react';
import { PropTypes } from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { isEmpty } from 'lodash';
import cx from 'classnames';
import { Icon } from '@unite-us/ui';
import { generateUUID } from 'common/utils/utils';
import FilePreview from '../FileUpload/components/FilePreview';
import './stylesheets/FileUpload.scss';

const MAX_FILE_SIZE = 10000000;

export const FileUpload = ({
  className,
  children,
  multiple,
  rejectedFiles,
  uploadedFiles,
  setRejectedFiles,
  setUploadedFiles,
  acceptedFileTypes,
}) => {
  const onDrop = (acceptedFiles) => {
    const fileUploadWithIdAndPreview = acceptedFiles.map((file) => ({
      file,
      tempId: generateUUID(),
      preview: URL.createObjectURL(file),
    }));

    setRejectedFiles({ filesTooBig: [], filesWrongType: [] });
    setUploadedFiles(multiple ? [...uploadedFiles, ...fileUploadWithIdAndPreview] : fileUploadWithIdAndPreview);
  };

  const onDropRejected = (fileRejections) => {
    const { filesTooBig, filesWrongType, filesTooBigAndWrongType } = fileRejections.reduce((acc, rejectedFile) => {
      if (rejectedFile.size > MAX_FILE_SIZE && !acceptedFileTypes.includes(rejectedFile.type)) {
        return { ...acc, filesTooBigAndWrongType: [...acc.filesTooBigAndWrongType, rejectedFile.name] };
      }

      if (rejectedFile.size > MAX_FILE_SIZE) {
        return { ...acc, filesTooBig: [...acc.filesTooBig, rejectedFile.name] };
      }

      if (!acceptedFileTypes.includes(rejectedFile.type)) {
        return { ...acc, filesWrongType: [...acc.filesWrongType, rejectedFile.name] };
      }

      return acc;
    }, { filesTooBig: [], filesWrongType: [], filesTooBigAndWrongType: [] });

    setRejectedFiles({ filesTooBig, filesWrongType, filesTooBigAndWrongType });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileTypes,
    maxSize: MAX_FILE_SIZE,
    onDrop,
    onDropRejected,
    multiple,
  });

  const { filesTooBig = [], filesWrongType = [], filesTooBigAndWrongType = [] } = rejectedFiles;

  return (
    <>
      {
        filesTooBigAndWrongType.length ? (
          <div>
            <div className="flex">
              <div>
                <Icon
                  className="pb-1 mr-2 fill-current text-red"
                  icon={'V2Warning'}
                  size={14}
                />
              </div>
              <div>
                <div className={'text-sm text-red'}>
                  This file type is not supported and exceeds the maximum upload size of 10MB.
                </div>
                <div className={'text-sm text-red mt-2'}>
                  Accepted formats are CSV .DOC .DOCX .PDF .RTF .TXT .XLS .XLSX .GIF .JPG .JPEG .PNG .MP3 .M4A .WAV .WMA
                </div>
              </div>
            </div>
            <ul>
              {
                filesTooBigAndWrongType.map(
                  (file) => <li key={file} className={'text-sm text-red list-disc mt-1'}>{file}</li>,
                )
              }
            </ul>
          </div>
        ) : null
      }
      {
        filesWrongType.length ? (
          <div className={`${filesTooBigAndWrongType.length ? 'mt-3' : ''}`}>
            <div className="flex">
              <div>
                <Icon
                  className="pb-1 mr-2 fill-current text-red"
                  icon={'V2Warning'}
                  size={14}
                />
              </div>
              <span className={'text-sm text-red'}>
                This file type is not supported. Accepted formats are CSV .DOC .DOCX .PDF .RTF
                .TXT .XLS .XLSX .GIF .JPG .JPEG .PNG .MP3 .M4A .WAV .WMA
              </span>
            </div>
            <ul>
              {
                filesWrongType.map(
                  (file) => <li key={file} className={'text-sm text-red list-disc mt-1'}>{file}</li>,
                )
              }
            </ul>
          </div>
        ) : null
      }
      {
        filesTooBig.length ? (
          <div className={`${(filesWrongType.length || filesTooBigAndWrongType.length) ? 'mt-3' : ''}`}>
            <div className="flex">
              <div>
                <Icon
                  className="pb-1 mr-2 fill-current text-red"
                  icon={'V2Warning'}
                  size={14}
                />
              </div>
              <span className={'text-sm text-red'}>
                The following file exceeds the maximum upload size of 10MB
              </span>
            </div>
            <ul>
              {
                filesTooBig.map((file) => (
                  <li key={file} className={'text-sm text-red list-disc mt-1'}>{file}</li>
                ))
              }
            </ul>
          </div>
        ) : null
      }
      <div
        {...getRootProps()}
        className={cx('mt-5 mb-3 file-upload-dropzone', className)}
      >
        <div className="file-upload-dropzone__preview">
          {children || (
            <p>
              <span className="file-upload-dropzone__preview__uploader-link">
                Choose file
              </span> or drag and drop to upload
            </p>
          )}
          {
            !isEmpty(uploadedFiles) && uploadedFiles.map(({ file, tempId, preview }) => (
              <div key={tempId} className="file-upload-dropzone__preview__preview-item">
                <FilePreview contentType={file?.type} preview={preview} />
                <div className="file-upload-dropzone__preview__file-name">
                  {file?.name}
                </div>
              </div>
            ))
          }
        </div>
        <input {...getInputProps()} />
      </div>
    </>
  );
};

FileUpload.propTypes = {
  className: PropTypes.string,
  multiple: PropTypes.bool,
  children: PropTypes.node,
  rejectedFiles: PropTypes.object.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  setRejectedFiles: PropTypes.func.isRequired,
  setUploadedFiles: PropTypes.func.isRequired,
  acceptedFileTypes: PropTypes.string,
};

FileUpload.defaultProps = {
  className: '',
  children: null,
  multiple: false,
  acceptedFileTypes: `text/csv, application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document,
  application/pdf, application/rtf, text/rtf, application/x-rtf, text/plain,
  application/excel, application/vnd.ms-excel, application/x-excel, application/x-msexcel,
  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
  application/vnd.ms-excel.sheet.binary.macroEnabled.12,
  application/vnd.ms-excel,
  application/vnd.ms-excel.sheet.macroEnabled.12,
  image/gif, image/jpg, image/jpeg, image/png, audio/mpeg, audio/m4a,
  audio/x-m4a, audio/wav, audio/x-wav, audio/x-ms-wma, video/x-ms-wma`,
};

export default FileUpload;
