import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  TableRow,
  TableRowColumn,
  Popover,
} from '@unite-us/ui';
import callOrLog from 'common/utils/callOrLog';
import { SEARCH } from 'common/utils/EventTracker/utils/eventConstants';
import { viewable } from 'common/utils/Permission/Permission';
import {
  getColumnHeaders,
  getItemResult,
  goToSearchResult,
} from '../utils';

class SearchTableRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popoverOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const { model, result } = this.props;
    const resultItem = getItemResult(result);

    if (viewable(resultItem)) {
      callOrLog(() => this.context.eventTracker(SEARCH.clickIndexResult));
      goToSearchResult(model, result);
    } else {
      this.setState({ popoverOpen: !this.state.popoverOpen });
    }
  }

  render() {
    const {
      index,
      model,
      parent,
      result,
      rowIndex,
    } = this.props;

    const { session } = this.context;
    const tableConfig = getColumnHeaders(model);
    const { headers, getRowValues } = tableConfig;
    const columns = _.map(headers, 'value');
    const key = `${model}-row-${index}`;

    return (
      <TableRow
        key={key}
        id={key}
        onCellClick={this.toggle}
        parent={parent}
        rowIndex={rowIndex}
      >
        {
          getRowValues({ result, columns, session }).map((column, i) => (
            <TableRowColumn
              id={`${model}-${columns[i]}-${index}`}
              key={`${model}-${columns[i]}-${index}`}
            >
              {column.value}
            </TableRowColumn>
          ))
        }
        <Popover
          body="You do not have permission to view"
          isOpen={this.state.popoverOpen}
          placement="bottom"
          target={key}
          toggle={this.toggle}
          id={`${model}-${rowIndex}-popover`}
        />
      </TableRow>
    );
  }
}

SearchTableRow.propTypes = {
  index: PropTypes.number.isRequired,
  model: PropTypes.string.isRequired,
  result: PropTypes.object.isRequired,
  /** Inherited props from the TableBody Component * */
  parent: PropTypes.string,
  /** Inherited props from the TableBody Component * */
  rowIndex: PropTypes.number,
};

SearchTableRow.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
  session: PropTypes.object,
};

export default SearchTableRow;
