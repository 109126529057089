import React from 'react';
import {
  CLIENT_RESULTS_COUNT,
  MODELS,
} from 'src/components/Search/constants';
import SearchBarSection from './SearchBarSection';

const { contact } = MODELS;

const SearchBarResults = () => (
  <div className="search-results">
    <SearchBarSection
      displayName="Clients"
      model={contact}
      numResults={CLIENT_RESULTS_COUNT}
    />
  </div>
);

export default SearchBarResults;
