import React from 'react';
import PropTypes from 'prop-types';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
} from '@unite-us/ui';
import FeeScheduleScreeningsTable from './FeeScheduleScreeningsTable';

const FeeScheduleScreenings = ({ feeScheduleId }) => (
  <div className="fee-schedule-base-card">
    <BaseCard className="p-6" noBorder>
      <BaseCardHeader title="Fee Schedule Screenings" noBorder className="px-6 font-bold" />
      <BaseCardBody className="px-6">
        <FeeScheduleScreeningsTable feeScheduleId={feeScheduleId} />
      </BaseCardBody>
    </BaseCard>
  </div>
);

FeeScheduleScreenings.propTypes = {
  feeScheduleId: PropTypes.string.isRequired,
};

export default FeeScheduleScreenings;
