import isServiceTypeInProgram from 'src/components/Referrals/ReferralDetail/utils/isServiceTypeInProgram';
import returnNoServiceTypeInProgramMessage from 'src/components/Referrals/ReferralDetail/utils/returnNoServiceTypeInProgramMessage';
import { HOLD_MODAL_DIALOG } from '../../constants';
import canHoldForReview from './canHoldForReview';

// TODO: might have to look within the Referral History hook instead of making the api call to
// `referral` in order to fetch the case referrals
const holdForReview = async ({
  referral,
  caseReferrals,
  groupId,
  user,
  openModal,
}) => {
  try {
    const sentReferrals = caseReferrals.filter((r) => r.state === 'sent');
    if (!canHoldForReview({ user, referral, groupId }) || sentReferrals.length > 1) {
      return null;
    }

    return {
      label: 'Hold For Review',
      value: 'holdForReview',
      className: 'take-action-hold-for-review',
      action: () => openModal(HOLD_MODAL_DIALOG),
      ...(!isServiceTypeInProgram(referral) ? {
        disabled: true,
        customProperties: { message: returnNoServiceTypeInProgramMessage({ action: 'holdForReview' }) },
      } : {}),
    };
  } catch (error) {
    return error;
  }
};

export default holdForReview;
