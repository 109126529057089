import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Icon, Dialog } from '@unite-us/ui';
import _ from 'lodash';
import { getEnumsFromState } from 'common/utils/Enums';
import DollarAmount from 'src/common/display/Money/DollarAmount';
import Address from 'src/common/display/Address';
import PhoneNumberDisplay from 'src/common/display/PhoneNumberDisplay';
import Email from 'src/common/display/Email';

// keys from assistance request `person_military` field which map to a front-end enum
const MILITARY_ENUMS = [
  'affiliation',
  'current_status',
  'currently_transitioning',
  'active_duty',
  'branch',
  'service_era',
  'deployed',
  'discharge_type',
  'discharge_disability',
  'connected_disability',
  'service_connected_disability_rating',
  'proof_of_veteran_status',
  'proof_type',
];

export const findNameToDisplay = (value, enumsPath, enums) => {
  const statusEnums = _.wget(enums, enumsPath, []);
  const foundStatus = statusEnums.find((element) => element.value === value);
  return foundStatus?.display_name || '';
};

const formatAttributeName = (attributeName) => attributeName
  .split('_')
  .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  .join(' ');

const TitleIcon = ({ icon, label }) => (
  <div className="flex items-center gap-1 mb-2 text-xs font-bold">
    <Icon className="fill-current text-brand-blue" icon={icon} />
    <h4>{label}</h4>
  </div>
);

const Field = ({ attribute, value = '' }) => (
  <>
    <h4>{attribute}</h4>
    <p>{value}</p>
  </>
);

const SubField = ({ attribute, value = '' }) => (
  <>
    <p>{attribute}</p>
    <p className="italic capitalize">{value}</p>
  </>
);

export const GrossMonthlyIncomeDisplay = ({ attributeName, value }) => (
  <Field
    attribute={formatAttributeName(attributeName)}
    value={<DollarAmount value={value} />}
  />
);

export const EnumDisplay = ({
  attributeName,
  value,
  path,
  enums,
  isSubField = false,
}) => {
  const formattedAttribute = formatAttributeName(attributeName);
  const formattedValue = findNameToDisplay(value, path, enums);
  const Component = isSubField ? SubField : Field;

  return (
    <Component
      attribute={formattedAttribute}
      value={formattedValue}
    />
  );
};

const TextDisplay = ({ attributeName, value, isSubField = false }) => {
  const formattedAttribute = formatAttributeName(attributeName);
  const Component = isSubField ? SubField : Field;

  return (
    <Component
      attribute={formattedAttribute}
      value={value}
    />
  );
};

const FullMilitaryInfo = ({ value, enums }) => Object.entries(value).map(([elementKey, element]) => {
    if (elementKey === 'affiliation') return null;

    // handle cases where the front end enum has a different name than the AR key
    let pathKey = elementKey;
    if (elementKey === 'currently_transitioning') pathKey = 'transitioning';
    if (elementKey === 'service_connected_disability_rating') pathKey = 'disability_rating';

    return (
      element && (
        <div>
          {
            MILITARY_ENUMS.includes(elementKey) ? (
              <EnumDisplay
                isSubField
                attributeName={elementKey}
                value={element}
                path={`contact_data.military.${pathKey}`}
                enums={enums}
              />
            ) : (
              <TextDisplay
                isSubField
                attributeName={elementKey}
                value={element}
              />
            )
          }
        </div>
      )
    );
  });

export const MilitaryDisplay = ({ value, enums }) => {
  if (Object.keys(value || {}).length === 0) return null;
  if (!value.affiliation) return null;

  const showFullMilitaryInfo = value.affiliation === 'military_member_or_veteran';

  return (
    <>
      <h4 className="mb-2">Military</h4>
      <div className="flex flex-col gap-2">
        <div>
          <EnumDisplay
            isSubField
            attributeName="affiliation"
            path="contact_data.military.affiliation"
            value={value.affiliation}
            enums={enums}
          />
        </div>
        { showFullMilitaryInfo ? <FullMilitaryInfo value={value} enums={enums} /> : null }
      </div>
    </>
  );
};

export const SexualityDisplay = ({ value, enums }) => {
  const sexualityText = value ?
    value.map((sexuality) => findNameToDisplay(sexuality, 'people.sexual_orientations', enums)).join(', ') :
    '';

  return (
    <TextDisplay
      attributeName={'sexuality'}
      value={sexualityText}
    />
  );
};

const attributeNameToPath = (attributeName) => `people.${attributeName}`;

const componentMap = {
  gross_monthly_income: { Component: GrossMonthlyIncomeDisplay },
  marital_status: { Component: EnumDisplay, path: attributeNameToPath('marital_status') },
  gender: { Component: EnumDisplay, path: attributeNameToPath('genders') },
  race: { Component: EnumDisplay, path: attributeNameToPath('race') },
  ethnicity: { Component: EnumDisplay, path: attributeNameToPath('ethnicity') },
  sexuality: { Component: SexualityDisplay },
  sexuality_other: { Component: TextDisplay },
  citizenship: { Component: EnumDisplay, path: attributeNameToPath('citizenship') },
  military: { Component: MilitaryDisplay },
  title: { Component: TextDisplay },
  suffix: { Component: TextDisplay },
};

const UpdateRowByAttributeName = ({ attributeName, values, enums }) => {
  const { Component, ...otherAttributes } = componentMap[attributeName] || { Component: null };

  if (!Component) return null;

  const isEnumDisplay = Component === EnumDisplay;
  const isMilitaryDisplay = Component === MilitaryDisplay;
  const isSexualityDisplay = Component === SexualityDisplay;

  return (
    <>
      <div className="col-start-1 col-end-1">
        <Component
          attributeName={attributeName}
          value={values[0]}
          {...otherAttributes}
          {...((isEnumDisplay || isMilitaryDisplay || isSexualityDisplay) && { enums })}
        />
      </div>
      <div className="col-start-2 col-end-2">
        <Component
          attributeName={attributeName}
          value={values[1]}
          {...otherAttributes}
          {...((isEnumDisplay || isMilitaryDisplay || isSexualityDisplay) && { enums })}
        />
      </div>
    </>
  );
};

export const AddedItemsByAttributeName = ({ values, type }) => {
  const components = {
    email_addresses: { component: Email, props: { noLink: true } },
    phone_numbers: { component: PhoneNumberDisplay, props: { noLink: true, typeDisplayInline: true } },
    addresses: { component: Address, props: { shortDisplay: true, inlineCounty: true } },
    };

  const typeConfig = components[type] || null;
  if (!typeConfig) return null;

  const { component: Component, props: componentProps } = typeConfig;

  return (
    <div className="text-xs">
      {values.map((value, idx) => {
        let key = value.id;

        if (!key) {
          const keyMap = {
            email_addresses: value?.email_address,
            phone_numbers: value?.phone_number,
            addresses: idx,
          };
          key = keyMap[type];
        }

        return (
          <Component key={key} item={value} {...componentProps} />
        );
      })}
    </div>
  );
};

export const ConfirmUseThisRecordDialog = (({
  dialogRef, onClose, onSubmit, content, enums,
}) => {
  const actions = (
    <div className="flex justify-end gap-4">
      <Button
        label="Cancel"
        onClick={onClose}
      />
      <Button
        primary
        label="Yes, Update"
        onClick={onSubmit}
      />
    </div>
  );

  const hasAdditions = content?.additions && Object.values(content?.additions).some((arr) => arr.length > 0);
  const hasUpdates = content?.updates && Object.keys(content?.updates).length > 0;

  return (
    <Dialog
      title="Update Client Record"
      ref={dialogRef}
      actions={actions}
      modal
      dialogContentStyles={{
        background: '#FAFBFC',
        padding: '16px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        textColor: '#182a44',
        fontSize: '13px',
      }}
      titleStyles={{ borderColor: '#E3EBF3' }}
      footerStyles={{ marginLeft: 0, borderColor: '#E3EBF3' }}
      customSize={{ width: '558px' }}
    >
      <div className="space-y-2">
        <p className="text-xs">
          Below is information from the assistance request that will update
          what&apos;s in the existing client record. Are you sure you want to
          update the data in the existing client record?
        </p>
        <p className="text-xs text-red">
          This action can&apos;t be undone.
        </p>
      </div>
      {hasUpdates && (
        <div className="grid grid-cols-2 gap-2">
          <TitleIcon
            icon="IconMinusCircle"
            label="Existing Client"
          />
          <TitleIcon
            icon="V2CheckCircle"
            label="Assistance Request"
          />
          {
            Object.entries(content.updates).map(([key, values]) => (
              <UpdateRowByAttributeName
                key={key} attributeName={key} values={values}
                enums={enums}
              />
          ))
}
        </div>
      )}
      {hasAdditions && (
        <div className="flex flex-col gap-2">
          <div className="space-y-1">
            <TitleIcon
              icon="IconPlusCircle"
              label="Additions"
            />
            <div>
              The following information from the assistance request will be added to the client record and
              marked as primary.
            </div>
          </div>
          <div className="flex flex-wrap gap-4">
            <div className="flex flex-col flex-1 gap-1">
              <h4>Address</h4>
              <AddedItemsByAttributeName values={content?.additions.addresses} type="addresses" />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <h4>Phone Number</h4>
              <AddedItemsByAttributeName values={content?.additions.phone_numbers} type="phone_numbers" />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <h4>Email</h4>
              <AddedItemsByAttributeName values={content?.additions.email_addresses} type="email_addresses" />
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
});

ConfirmUseThisRecordDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dialogRef: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  enums: PropTypes.any.isRequired,
};

TitleIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

AddedItemsByAttributeName.propTypes = {
  values: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

UpdateRowByAttributeName.propTypes = {
  attributeName: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  enums: PropTypes.any.isRequired,
};

SexualityDisplay.propTypes = {
  value: PropTypes.object,
  enums: PropTypes.any.isRequired,
};

MilitaryDisplay.propTypes = {
  value: PropTypes.object,
  enums: PropTypes.any.isRequired,
};

FullMilitaryInfo.propTypes = {
  value: PropTypes.object.isRequired,
  enums: PropTypes.any.isRequired,
};

GrossMonthlyIncomeDisplay.propTypes = {
  attributeName: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

TextDisplay.propTypes = {
  attributeName: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  isSubField: PropTypes.bool,
};

EnumDisplay.propTypes = {
  attributeName: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  enums: PropTypes.any.isRequired,
  isSubField: PropTypes.bool,
};

Field.propTypes = {
  attribute: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
};

SubField.propTypes = {
  attribute: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
};

MilitaryDisplay.defaultProps = { value: {} };
EnumDisplay.defaultProps = { isSubfield: false };
TextDisplay.defaultProps = { isSubfield: false };

function mapStateToProps(state) {
  return {
    enums: getEnumsFromState(state),
  };
}

export default connect(mapStateToProps, null)(ConfirmUseThisRecordDialog);
