import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '@unite-us/ui';
import { getColumnHeaders } from '../utils';

const SearchTableHeader = ({ model }) => {
  const tableConfig = getColumnHeaders(model);
  const headers = get(tableConfig, 'headers', []);

  return (
    <TableHeader>
      <TableRow>
        {
          headers.map((column) => (
            <TableHeaderColumn
              key={column.value}
              id={`${model}-header-${column.value}`}
            >
              {column.label}
            </TableHeaderColumn>
          ))
        }
      </TableRow>
    </TableHeader>
  );
};

SearchTableHeader.propTypes = {
  model: PropTypes.string.isRequired,
};

export default SearchTableHeader;
