import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Icon } from '@unite-us/ui';
import './BrowseMapButton.scss';

const BrowseMapButton = ({
  className,
  ...props
}) => (
  <Button
    ariaHasPopUp="dialog"
    className={classNames('browse-map-button', className)}
    iconRight={<Icon icon="IconCaretRight" />}
    {...props}
  />
);

BrowseMapButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

BrowseMapButton.defaultProps = {
  className: '',
  label: 'Browse Map',
};

export default BrowseMapButton;
