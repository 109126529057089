import _ from 'lodash';
import { addresses as addressesUtils, phones as phonesUtils } from '@unite-us/client-utils';
import { dates } from '@unite-us/app-components';
import findPrimaryOrFirst from 'src/components/Contacts/utils/findPrimaryOrFirst';
import { getItemResult } from '.';

function formatCityState(addresses) {
  const address = addressesUtils.findMainAddress(addresses);

  if (_.isEmpty(address)) {
    return '';
  }

  return _.compact([
    _.get(address, 'city'),
    _.get(address, 'state'),
  ]).join(', ');
}

function formatPhone(contact) {
  const primaryPhone = findPrimaryOrFirst(_.get(contact, 'phone_numbers', []));
  return phonesUtils.formatPhoneNumber(_.get(primaryPhone, 'phone_number', ''));
}

function formatEmail(contact) {
  const primaryEmail = findPrimaryOrFirst(_.get(contact, 'email_addresses', []));
  return _.get(primaryEmail, 'email_address', '');
}

function formatClientData({ result, columns }) {
  const contact = getItemResult(result);

  const clientData = {
    full_name: _.get(contact, 'full_name', ''),
    phone: formatPhone(contact),
    dob: dates.formatDate(contact.date_of_birth, 'MM/DD/YYYY'),
    location: formatCityState(contact.addresses),
    email: formatEmail(contact),
  };

  return _.reduce(columns, (acc, value) => {
    if (_.has(clientData, value)) {
      return [
        ...acc,
        {
          label: value,
          value: clientData[value],
        },
      ];
    }
    return [...acc];
  }, []);
}

export default formatClientData;
