import Notifier from 'common/helpers/Notifier';
import { isEmpty, isEqual, set } from 'lodash';
import {
  SET_SEARCH_FETCHING,
  UNSET_SEARCH_FETCHING,
  FETCH_SEARCH_INDEX_RESULTS,
  SET_SEARCH_INDEX_FETCHING,
  UNSET_SEARCH_INDEX_FETCHING,
} from 'actions';
import {
  CLIENT_RESULTS_COUNT,
  DEFAULT_RECORD_TYPE,
} from 'src/components/Search/constants';
import { coreApi } from 'src/api/config';

import axios from 'axios';

const resources = {
  contact: 'person',
  cases_servicecase: 'case',
};

export default function fetchSearchResults({
  groupId,
  query,
  model = DEFAULT_RECORD_TYPE,
  page = 1,
  per = CLIENT_RESULTS_COUNT,
  isIndexFetch = false,
}) {
  return async (dispatch) => {
    try {
      const resource = resources[model];
      const CancelToken = axios.CancelToken;
      let cancelMethod;
      let params = {};
      const pageOptions = {
        sort: 'similarity',
        page: {
          number: page,
          size: per,
        },
        httpConfig: {
          cancelToken: new CancelToken((c) => { cancelMethod = c; }),
        },
      };

      switch (model) {
        case 'contact':
          params = {
            query,
            'client_relationships.provider': groupId,
          };
          pageOptions.include = 'addresses';
          break;
        case 'cases_servicecase':
          params = {
            query,
            provider: groupId,
          };
          break;
        default: break;
      }

      const promise = coreApi.query(resource, params, pageOptions);

      if (isIndexFetch) {
        dispatch({
          type: SET_SEARCH_INDEX_FETCHING,
          cancel: cancelMethod,
        });
      } else {
        dispatch({
          type: SET_SEARCH_FETCHING,
          cancel: cancelMethod,
        });
      }

      const payload = await promise;
      await Promise.all([
        coreApi.populateRelationship('person', 'person', payload.data.data),
        coreApi.populateRelationship('program', 'program', payload.data.data),
        coreApi.populateRelationship('service', 'service', payload.data.data),
        coreApi.populateRelationship('primary_worker', 'employee', payload.data.data),
      ]);
      if (isEqual(model, 'contact')) {
        await Promise.all(payload.data.data.map(async (person) => {
          if (!isEmpty(person.addresses)) {
            const addresses = await coreApi.query('addresses', { person: person.id });
            set(person, 'addresses', addresses.data.data);
          }
        }));
      }
      // This endpoint returned the data in this structure:
      // Mimicking the same structure to avoid refactoring all the components using this data
      // {
      //   contact: [{
      //     item: {
      //       result: {...}
      //     }
      //   }],
      //   cases_servicecase: [...] same
      // }
      payload.data.data = {
        [model]: {
          ...payload.data,
          data: payload.data.data.map((d) => ({ item: { result: d } })),
        },
      };

      if (isIndexFetch) {
        dispatch({
          type: FETCH_SEARCH_INDEX_RESULTS,
          payload,
        });

        dispatch({
          type: UNSET_SEARCH_INDEX_FETCHING,
        });
      }

      dispatch({
        type: UNSET_SEARCH_FETCHING,
      });

      return payload.data.data;
    } catch (error) {
      if (!isEmpty(error.message)) {
        dispatch({
          type: UNSET_SEARCH_FETCHING,
        });

        dispatch({
          type: UNSET_SEARCH_INDEX_FETCHING,
        });
      }

      if (error.message !== 'canceled') {
        Notifier.handleErrors(error);
      }

      return error;
    }
  };
}
