import { screeningsApiClient } from 'src/api/config';
import * as env from 'src/config/env/env.config';
import {
  ELIGIBILITY_ASSESSMENT_STATUS,
  DECLINED_REASON,
} from 'src/pages/facesheet/_id/eligibility/utils';

// subject_id is the client/person (id)
// To be done: facilitator is the employee (id)
export const submitAssessment = async ({
  personId,
  // employeeId,
  status,
  answers,
  related_screen_id,
}) => {
  const payload = {
    source: 'web_app',
    template_id: env.ELIGIBILITY_ASSESSMENT_TEMPLATE,
    status,
    subject_id: personId,
    related_screen_id,
    // facilitator: employeeId,
  };
  if (status === ELIGIBILITY_ASSESSMENT_STATUS.DECLINED) {
    payload.decline_reason = {
      key: 'other',
      note: DECLINED_REASON,
    };
  }
  if (
    status === ELIGIBILITY_ASSESSMENT_STATUS.COMPLETE ||
    status === ELIGIBILITY_ASSESSMENT_STATUS.DRAFT
  ) {
    payload.answers = answers;
  }

  const response = await screeningsApiClient.post('/screenings', payload);
  return response;
};
