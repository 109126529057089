import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/ui';

const ICON_DIRECTIONS = {
  asc: 'IconCaretUp',
  desc: 'IconCaretDown',
};

const TableHeaderColumnSortableIcon = ({ sortDirection }) => {
  if (['asc', 'desc'].includes(sortDirection)) {
    return (
      <Icon
        className="table-header-column-sortable-icon"
        icon={ICON_DIRECTIONS[sortDirection]}
        size={18}
      />
    );
  }

  return (<span />);
};

TableHeaderColumnSortableIcon.propTypes = {
  sortDirection: PropTypes.string.isRequired,
};

export default TableHeaderColumnSortableIcon;
