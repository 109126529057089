import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useCurrentPayerId, useCurrentProviderId, useIsNetworkLead } from 'common/contexts/CurrentProviderContext';
import { INVOICE } from 'common/utils/EventTracker/utils/eventConstants';
import { TrackerContext } from '@unite-us/client-utils';
import callOrLog from 'src/common/utils/callOrLog';
import { SelectField } from 'components/Backoffice/form/SelectField';
import { SearchableField } from 'components/Backoffice/form/SearchableField';
import { SearchableSelectField } from 'components/Backoffice/form/SearchableSelectField';
import { hasPayerInvoicesRole, useInvoiceDisputeWorkflow } from 'common/utils/FeatureFlags/flags';
import { useFind } from 'src/api/APIHooks';
import { connect } from 'react-redux';
import { noop, get, assign, merge, isEmpty } from 'lodash';
import {
  useInvoiceShortIdOptions,
  usePayerProviderOptions,
  useManagedProviderOptions,
  useFeeScheduleProgramOptions,
  useInvoiceClientNameOptions,
  useInvoicePayerOptions,
} from 'src/pages/invoices/hooks';
import { Button, DurationField } from '@unite-us/ui';
import moment from 'moment';
import * as options from './constants';
import { hourOnlyISODatetime } from './utils';
import useInvoiceDisputeReasonOptions from '../../hooks/useInvoiceDisputeReasonOptions';
import { selectUniqueDisplayValues } from '../../utils/selectUniqueDisplayValues';

const AllFiltersDrawer = ({
  setLastUpdated,
  setPayerProvider,
  setInvoicePayer,
  setManagedProvider,
  setServiceProvided,
  setStatus,
  setInvoiceShortId,
  setInvoiceClientName,
  onUpdateFilter,
  networkId,
  onSuccess,
  feeScheduleIds,
  statusOptionsConstant,
  setDateDuration,
  setFilterByInvoiceDate,
  setDisputeResolution,
  underDispute,
  setDisputeReason,
  dateDuration,
  disputeResolution,
  disputeReason,
  status,
  invoiceShortId,
  invoiceClientName,
  lastUpdated,
  payerProvider,
  invoicePayer,
  managedProvider,
  serviceProvided,
  showPayerInvoices,
  archived,
  payerWQInteractiveView,
  showInvoiceDisputeWorkflow,
}) => {
  const isNetworkLead = useIsNetworkLead();
  const [localStatus, setLocalStatus] = useState(status);
  const [localShortId, setLocalShortId] = useState(invoiceShortId);
  const [localLastUpdated, setLocalLastUpdated] = useState(lastUpdated);
  const [localProvider, setLocalProvider] = useState(payerProvider);
  const [localManagedProvider, setLocalManagedProvider] = useState(managedProvider);
  const [localInvoicePayer, setLocalInvoicePayer] = useState(invoicePayer);
  const [localClientName, setLocalClientName] = useState(invoiceClientName);
  const [localServiceProvided, setLocalServiceProvided] = useState(serviceProvided);
  const [localDisputeResolution, setLocalDisputeResolution] = useState(disputeResolution);
  const [localDisputeReason, setLocalDisputeReason] = useState(disputeReason);
  const [startField, setStartField] = useState({
    value: !isEmpty(dateDuration.provided_service_starts_at) ?
      moment(dateDuration.provided_service_starts_at).unix() : '',
    valid: true,
    invalid: false,
    touched: false,
    pristine: true,
    dirty: false,
    visited: false,
    error: '',
  });
  const [endField, setEndField] = useState({
    value: !isEmpty(dateDuration.provided_service_ends_at) ?
    moment(dateDuration.provided_service_ends_at).unix() : '',
    valid: true,
    invalid: false,
    touched: false,
    pristine: true,
    dirty: false,
    visited: false,
    error: '',
  });

  const { data } = useFind(
    'invoice_dispute_resolution_reasons',
    {
      fee_schedule: feeScheduleIds.join(),
    },
    {
      queryConfig: {
        placeholderData: undefined,
      },
    },
  );

  const invoice_dispute_resolution_reasons = get(data, 'data.data', []);
  const disputeReasons = useInvoiceDisputeReasonOptions(feeScheduleIds);
  const disputeReasonOptions = selectUniqueDisplayValues(disputeReasons);

  const disputeResolutionReasonOptions = selectUniqueDisplayValues(invoice_dispute_resolution_reasons);

  const payerId = useCurrentPayerId();
  const payerProviders = usePayerProviderOptions(payerId);

  const providerId = useCurrentProviderId();
  const servicesProvided = useFeeScheduleProgramOptions(providerId);

  const nlProviders = useManagedProviderOptions(providerId);
  let statusOptions;
  if (showPayerInvoices) {
    statusOptions = options.PAYER_STATUS_OPTIONS;
    if (!payerWQInteractiveView) {
      statusOptions = options.READ_ONLY_ALL_PAYER_STATUS_OPTIONS;
    }
  } else if (isNetworkLead) {
    statusOptions = options.NL_STATUS_OPTIONS;
  } else {
    statusOptions = options.STATUS_OPTIONS;
  }

  if (!showInvoiceDisputeWorkflow) {
    statusOptions = statusOptions.filter((option) => (option.label !== 'In Dispute'));
  }

  if (statusOptionsConstant) {
    statusOptions = options[statusOptionsConstant];

    if (underDispute && showPayerInvoices) {
      statusOptions = options.CLOSED_PAYER_STATUS_OPTIONS;
    }
  }

  if (statusOptionsConstant === 'OPEN_PAYER_STATUS_OPTIONS' && !payerWQInteractiveView) {
    statusOptions = options.READ_ONLY_OPEN_PAYER_STATUS_OPTIONS;
  }

  statusOptions = statusOptions.map((option) => (
    { label: option.label, value: option.value, id: option.value }));

  const path = window.location.pathname.split('/').pop();
  let showDisputeReason = false;
  let showDisputeResolution = false;

  if (showInvoiceDisputeWorkflow) {
    if ((isNetworkLead || showPayerInvoices)) {
      if (path === 'disputed') {
        showDisputeReason = true;
      } else {
        showDisputeReason = true;
        showDisputeResolution = true;
      }
    } else if (path === 'disputed' || path === 'archive' || path === 'all') {
        showDisputeReason = true;
        showDisputeResolution = true;
    }
  }

  const lastUpdatedOptions = options.LAST_UPDATED_OPTIONS.map((val) => (
    {
      label: val > 1 ? `Last ${val.toString()} days` : 'Today',
      value: hourOnlyISODatetime(val),
    }
  ));

  const startCallbacks = {
    onChange: (value) => {
      const newStartField = assign({}, startField, { value, dirty: true, pristine: false });
      setStartField({
        ...newStartField,
      });
    },
  };

  const endCallbacks = {
    onChange: (value) => {
      const newEndField = assign({}, endField, { value, dirty: true, pristine: false });
      setEndField({
      ...newEndField,
      });
    },
  };

  const eventTracker = useContext(TrackerContext);

  const onSubmit = () => {
      setStatus(localStatus);
      callOrLog(() => eventTracker(
        INVOICE.invoiceFilterStatusClicked,
        { current_network: networkId },
      ));

      setInvoiceShortId(localShortId);
      callOrLog(() => eventTracker(
        INVOICE.invoiceFilterInvoiceShortIdClicked,
        { current_network: networkId },
      ));

      setLastUpdated(localLastUpdated);
      callOrLog(() => eventTracker(
        INVOICE.invoiceFilterLastUpdatedClicked,
        { current_network: networkId },
      ));

      setPayerProvider(localProvider);
      setManagedProvider(localManagedProvider);
      callOrLog(() => eventTracker(
        INVOICE.invoiceFilterProviderClicked,
        { current_network: networkId },
      ));

      setInvoiceClientName(localClientName);
      callOrLog(() => eventTracker(
        INVOICE.invoiceFilterClientNameClicked,
        { current_network: networkId },
      ));

      setInvoicePayer(localInvoicePayer);
      callOrLog(() => eventTracker(
        INVOICE.invoiceFilterPayerClicked,
        { current_network: networkId },
      ));

      setServiceProvided(localServiceProvided);
      callOrLog(() => eventTracker(
        INVOICE.invoiceFilterServiceProvidedClicked,
        { current_network: networkId },
      ));

      setDisputeResolution(localDisputeResolution);
      callOrLog(() => eventTracker(
        INVOICE.invoiceFilterResolutionReasonClicked,
        { current_network: networkId },
      ));

      setDisputeReason(localDisputeReason);
      callOrLog(() => eventTracker(
        INVOICE.invoiceFilterDisputeReasonClicked,
        { current_network: networkId },
      ));

      const dateDurationValues = {
        provided_service_starts_at: startField.value ? moment.unix(startField.value).toString() : '',
        provided_service_ends_at: endField.value ? moment.unix(endField.value).toString() : '',
      };

      setDateDuration(dateDurationValues);
      setFilterByInvoiceDate(true);

      callOrLog(() => eventTracker(
        INVOICE.invoiceFilterDateDurationClicked,
        { current_network: networkId },
      ));

    onUpdateFilter();
    onSuccess();
  };

  const clearFilters = () => {
    setLocalStatus('');
    setLocalShortId('');
    setLocalLastUpdated('');
    setLocalProvider('');
    setLocalManagedProvider('');
    setLocalClientName('');
    setLocalInvoicePayer('');
    setLocalServiceProvided('');
    setLocalDisputeResolution('');
    setLocalDisputeReason('');
    setStartField({
      value: '',
      valid: true,
      invalid: false,
      touched: false,
      pristine: true,
      dirty: false,
      visited: false,
      error: '',
    });
    setEndField({
      value: '',
      valid: true,
      invalid: false,
      touched: false,
      pristine: true,
      dirty: false,
      visited: false,
      error: '',
    });
    setFilterByInvoiceDate(false);
  };

  const shortIdFilters = {
    invoice_status: statusOptions.map((i) => i.value).join(','),
    has_user_archive: archived,
    under_dispute: underDispute,
  };

  const shortIdOptions = useInvoiceShortIdOptions(
    providerId,
    payerId,
    isNetworkLead,
    localShortId,
    shortIdFilters,
    statusOptionsConstant,
  );
  const clientNameOptions = useInvoiceClientNameOptions(
    providerId,
    payerId,
    isNetworkLead,
    localClientName,
    shortIdFilters,
    statusOptionsConstant,
  );
  const invoicePayerOptions = useInvoicePayerOptions(
    providerId,
    isNetworkLead,
  );

  let conditionalSelectField;
  if (showPayerInvoices) {
    conditionalSelectField = (
      <div className="col-9 my-3 flex flex-col mx-6 px-4">
        <h3
          className="block mb-1 leading-snug font-extrabold font-medium-font normal-case text-13px text-text-blue mx-4"
        >
          Provider
        </h3>
        <SelectField
          className="w-full mx-4"
          label="Provider"
          onChange={({ value }) => setLocalProvider(value)}
          options={payerProviders}
          value={localProvider}
          placeholder="Choose a Provider"
          clearable
          hideLabel
        />
      </div>
    );
  } else if (isNetworkLead) {
    conditionalSelectField = (
      <div className="col-9 my-3 flex flex-col mx-6 px-4">
        <h3
          className="block mb-1 leading-snug font-extrabold font-medium-font normal-case text-13px text-text-blue mx-4"
        >
          Provider
        </h3>
        <SearchableSelectField
          className="w-full mx-4"
          label="Provider"
          onChange={({ value }) => setLocalManagedProvider(value)}
          options={nlProviders}
          placeholder="Choose Provider"
          value={localManagedProvider}
          truncateOptions={false}
          clearable
          hideLabel
        />
      </div>
    );
  } else {
    conditionalSelectField = (
      <div className="col-9 my-3 flex flex-col mx-6 px-4">
        <h3
          className="block mb-1 leading-snug font-extrabold font-medium-font normal-case text-13px text-text-blue mx-4"
        >
          Service Provided
        </h3>
        <SearchableSelectField
          className="w-full mx-4"
          label="Service Provided"
          onChange={({ value }) => setLocalServiceProvided(value)}
          options={servicesProvided}
          placeholder="Choose Service Provided"
          value={localServiceProvided}
          truncateOptions={false}
          clearable
          hideLabel
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col pt-6 mb-0 h-full overflow-auto">
      <div className="col-9 mx-6 px-6 pt-4">
        <div className="col-9 flex flex-col text-text-blue w-5/6 mx-0 px-2">
          <h1 className="mb-2 font-extrabold">
            Filters
          </h1>
          <p className="">
            Add filters to refine your search.
          </p>
        </div>
      </div>
      <div className="col-9 my-3 flex flex-col mx-6 px-4">
        <h3
          className="block mb-1 leading-snug font-extrabold font-medium-font normal-case text-13px text-text-blue mx-4"
        >
          Invoice Status
        </h3>
        <SelectField
          className="w-full mx-4"
          label="Status"
          onChange={({ value }) => setLocalStatus(value)}
          options={statusOptions}
          value={localStatus}
          placeholder="Choose Status"
          clearable
          hideLabel
        />
      </div>
      <div className="col-9 my-3 flex flex-col mx-6 px-4">
        <h3
          className="block mb-1 leading-snug font-extrabold font-medium-font normal-case text-13px text-text-blue mx-4"
        >
          Invoice ID
        </h3>
        <SearchableField
          className="w-full mx-4"
          label="Invoice ID"
          onChange={({ value }) => setLocalShortId(value)}
          searchQuery={localShortId}
          options={shortIdOptions}
          onInputChange={setLocalShortId}
          placeholder={'Search for an invoice'}
          truncateOptions={false}
          clearable
          hideLabel
        />
      </div>
      <div className="col-9 my-3 flex flex-col mx-6 px-4">
        <h3
          className="block mb-1 leading-snug font-extrabold font-medium-font normal-case text-13px text-text-blue mx-4"
        >
          Last Updated
        </h3>
        <SelectField
          className="w-full mx-4"
          label="Last Updated"
          onChange={({ value }) => setLocalLastUpdated(value)}
          options={lastUpdatedOptions}
          placeholder="Choose Last Updated"
          value={localLastUpdated}
          clearable
          hideLabel
        />
      </div>
      { conditionalSelectField }
      <div className="col-9 my-3 flex flex-col mx-6 px-4">
        <h3
          className="block mb-1 leading-snug font-extrabold font-medium-font normal-case text-13px text-text-blue mx-4"
        >
          Client
        </h3>
        <SearchableField
          className="w-full mx-4"
          label="Client Name"
          onChange={({ value }) => setLocalClientName(value)}
          searchQuery={localClientName}
          options={clientNameOptions}
          onInputChange={setLocalClientName}
          placeholder={'Search for a client'}
          truncateOptions={false}
          clearable
          hideLabel
        />
      </div>
      {
        !showPayerInvoices && (
          <div className="col-9 my-3 flex flex-col mx-6 px-4">
            <h3
              className="block mb-1 font-extrabold font-medium-font normal-case text-13px text-text-blue mx-4"
            >
              Payer
            </h3>
            <SelectField
              className="w-full mx-4"
              label="Payer"
              onChange={({ value }) => setLocalInvoicePayer(value)}
              options={invoicePayerOptions}
              value={localInvoicePayer}
              placeholder="Choose a Payer"
              clearable
              hideLabel
            />
          </div>
        )
      }
      {
        showDisputeReason && (
          <div className="col-9 my-3 flex flex-col mx-6 px-4">
            <h3
              className="block mb-1 font-extrabold font-medium-font normal-case text-13px text-text-blue mx-4"
            >
              Dispute Reason
            </h3>
            <SelectField
              className="w-full mx-4"
              label="Dispute Reason"
              onChange={({ value }) => setLocalDisputeReason(value)}
              options={disputeReasonOptions}
              value={localDisputeReason}
              placeholder="Choose a dispute reason"
              clearable
              hideLabel
            />
          </div>
        )
      }
      {
        showDisputeResolution && (
          <div className="col-9 my-3 flex flex-col mx-6 px-4">
            <h3
              className="block mb-1 font-extrabold font-medium-font normal-case text-13px text-text-blue mx-4"
            >
              Dispute Resolution
            </h3>
            <SelectField
              className="w-full mx-4"
              label="Dispute Resolution"
              onChange={({ value }) => setLocalDisputeResolution(value)}
              options={disputeResolutionReasonOptions}
              value={localDisputeResolution}
              placeholder="Choose a dispute resolution"
              clearable
              hideLabel
            />
          </div>
        )
      }
      <div className="my-3 grid grid-cols-12 gap-2">
        <div className="col-start-2 col-span-4 mx-0">
          <h3 className="block mb-1 leading-snug font-extrabold font-medium-font normal-case text-13px text-text-blue">
            Service Delivery Date(s)
          </h3>
          <DurationField
            className="w-80"
            id="payer-filters-drawer-date-filter"
            label="Service Delivery Date(s)"
            startField={merge(startField, startCallbacks)}
            endField={merge(endField, endCallbacks)}
            placeholder="Choose Date Range"
            hideLabel
          />
        </div>
      </div>
      <div className="col-5 mt-3 flex flex-row justify-end mx-6 px-6 mb-6 pb-6">
        <button
          aria-label="Clear all filters"
          className="text-action-blue mx-4"
          onClick={clearFilters}
          type="button"
        >
          <span className="text-action-blue hover:text-text-blue  hover:text-text-blue text-md">
            Clear all
          </span>
        </button>
        <Button
          className="primary-button"
          label="Apply filters"
          primary
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

AllFiltersDrawer.propTypes = {
  networkId: PropTypes.string,
  onUpdateFilter: PropTypes.func.isRequired,
  setLastUpdated: PropTypes.func.isRequired,
  setPayerProvider: PropTypes.func.isRequired,
  setServiceProvided: PropTypes.func,
  setManagedProvider: PropTypes.func,
  setStatus: PropTypes.func,
  setInvoiceShortId: PropTypes.func,
  setInvoiceClientName: PropTypes.func,
  setInvoicePayer: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  feeScheduleIds: PropTypes.array.isRequired,
  statusOptionsConstant: PropTypes.string,
  setFilterByInvoiceDate: PropTypes.func,
  setDateDuration: PropTypes.func,
  showPayerInvoices: PropTypes.bool,
  underDispute: PropTypes.bool,
  setDisputeResolution: PropTypes.func,
  setDisputeReason: PropTypes.func,
  disputeResolution: PropTypes.string.isRequired,
  disputeReason: PropTypes.string.isRequired,
  dateDuration: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  invoiceShortId: PropTypes.string.isRequired,
  invoiceClientName: PropTypes.string.isRequired,
  invoicePayer: PropTypes.string.isRequired,
  lastUpdated: PropTypes.string.isRequired,
  payerProvider: PropTypes.string,
  managedProvider: PropTypes.string,
  serviceProvided: PropTypes.string,
  archived: PropTypes.bool.isRequired,
  payerWQInteractiveView: PropTypes.bool,
  showInvoiceDisputeWorkflow: PropTypes.bool,
 };

AllFiltersDrawer.defaultProps = {
  setStatus: noop,
  setInvoiceShortId: noop,
  setServiceProvided: noop,
  setManagedProvider: noop,
  setInvoiceClientName: noop,
  setInvoicePayer: noop,
  statusOptionsConstant: '',
  networkId: '',
  payerProvider: '',
  managedProvider: '',
  serviceProvided: '',
  setFilterByInvoiceDate: noop,
  setDateDuration: noop,
  showPayerInvoices: false,
  underDispute: false,
  setDisputeResolution: noop,
  setDisputeReason: noop,
  payerWQInteractiveView: false,
  showInvoiceDisputeWorkflow: false,
};

function mapStateToProps(state) {
  const networkId = state.networks.networkId;
  return {
    networkId,
    showPayerInvoices: hasPayerInvoicesRole(state),
    showInvoiceDisputeWorkflow: useInvoiceDisputeWorkflow(state),
  };
}

export default connect(mapStateToProps)(AllFiltersDrawer);
