import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { getSearchBarColumnHeaders } from '../utils';

const SearchBarTableHeader = ({ model }) => {
  const tableConfig = getSearchBarColumnHeaders(model);
  const headers = get(tableConfig, 'headers', [])
    .map((column, key) => (
      <td
        className="search-result__column"
        key={`${model}-column-header-${key}`}
      >
        {column.label}
      </td>
    ));

  return (
    <tr className="search-results-section-header">
      {headers}
    </tr>
  );
};

SearchBarTableHeader.propTypes = {
  model: PropTypes.string.isRequired,
};

export default SearchBarTableHeader;
