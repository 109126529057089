import React from 'react';
import { DashboardNavigationLink, LeftNavigationBar } from 'common/Navigation';
import { AUTHORIZATION_STATUSES } from 'common/Status/authorizationConstants';
import { useCurrentPayerId } from 'common/contexts/CurrentProviderContext';
import { useServiceAuthorizationStatusCount } from '../hooks/useServiceAuthorizationStatusCount';

export const StatusSelectBar = () => {
  const payerId = useCurrentPayerId();
  const openCount = useServiceAuthorizationStatusCount(AUTHORIZATION_STATUSES.open, payerId);
  return (
    <LeftNavigationBar className="space-y-1 -mb-20">
      <DashboardNavigationLink
        className="text-base"
        label="Open"
        count={openCount}
        path="/authorizations/open"
      />
      <DashboardNavigationLink
        className="text-base"
        label="Accepted"
        path="/authorizations/accepted"
      />
      <DashboardNavigationLink
        className="text-base"
        label="Rejected"
        path="/authorizations/rejected"
      />
      <DashboardNavigationLink
        className="text-base"
        label="All Requests"
        path="/authorizations/all"
      />
    </LeftNavigationBar>
  );
};
