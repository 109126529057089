import PropTypes from 'prop-types';
import React from 'react';
import DetailDescription from 'common/display/SingleItemDetails/dashboard/DetailDescription';
import DetailSummaryForm from 'common/display/SingleItemDetails/DetailSummaryForm';
import EditModal from 'common/modal/EditModal';
import { noop } from 'lodash';
import classnames from 'classnames';
import '../stylesheets/detail-view.scss';

function DetailSummary(props) {
  const {
    className,
    description,
    editable,
    submit,
    descriptionTitle,
    descriptionEditSubtitle,
    descriptionSubtitle,
    item,
  } = props;

  return (
    <div className={classnames('detail-summary', 'detail-info__summary', className)}>
      <div className="row detail-info__description-container">
        <div className="col-md-12">
          <div className="detail-info__edit-section detail-info__edit-description">
            <EditModal
              id="edit-detail-description-modal"
              buttonId="edit-detail-description"
              header="Edit"
              size="large"
              showEdit={editable}
              formName="editDetailField"
              labelText="Edit"
            >
              <DetailSummaryForm
                descriptionSubtitle={descriptionEditSubtitle}
                detailObj={item}
                notes={description}
                submit={submit}
              />
            </EditModal>
          </div>
          <DetailDescription
            id="detail-description-expandable"
            title={descriptionTitle}
            subtitle={descriptionSubtitle}
            description={description}
          />
        </div>
      </div>
    </div>
  );
}

DetailSummary.propTypes = {
  className: PropTypes.string,
  editable: PropTypes.bool,
  description: PropTypes.string,
  descriptionEditSubtitle: PropTypes.node,
  descriptionSubtitle: PropTypes.node,
  descriptionTitle: PropTypes.string,
  item: PropTypes.shape({
    _meta: PropTypes.shape({
      _type: PropTypes.string,
    }),
  }).isRequired,
  submit: PropTypes.func,
};

DetailSummary.defaultProps = {
  className: '',
  editable: true,
  description: '',
  descriptionEditSubtitle: null,
  descriptionSubtitle: null,
  descriptionTitle: 'Description',
  submit: noop,
};

export default DetailSummary;
