import { useCallback, useState } from 'react';
import { isEmpty, some } from 'lodash';

const useInvoiceFilters = () => {
  const [status, setStatus] = useState('');
  const [underDispute, setUnderDispute] = useState(false);
  const [lastUpdated, setLastUpdated] = useState('');
  const [serviceProvided, setServiceProvided] = useState('');
  const [managedProvider, setManagedProvider] = useState('');
  const [payerProvider, setPayerProvider] = useState('');
  const [invoiceShortId, setInvoiceShortId] = useState('');
  const [invoiceClientName, setInvoiceClientName] = useState('');
  const [invoicePayer, setInvoicePayer] = useState('');

  const [dateDuration, setDateDuration] = useState({
    provided_service_starts_at: '',
    provided_service_ends_at: '',
  });
  const [disputeResolution, setDisputeResolution] = useState('');
  const [disputeReason, setDisputeReason] = useState('');

  const isFiltered = some(
    [status,
      underDispute,
      lastUpdated,
      serviceProvided,
      managedProvider,
      payerProvider,
      invoiceShortId,
      invoiceClientName,
      invoicePayer,
      disputeResolution,
      disputeReason,
      dateDuration.provided_service_starts_at,
      dateDuration.provided_service_ends_at,
    ],
    (filter) => !isEmpty(filter),
  );
  const resetFilters = useCallback(() => {
    setStatus('');
    setUnderDispute(false);
    setLastUpdated('');
    setServiceProvided('');
    setManagedProvider('');
    setPayerProvider('');
    setInvoiceShortId('');
    setInvoiceClientName('');
    setInvoicePayer('');
    setDateDuration({});
    setDisputeResolution('');
    setDisputeReason('');
  }, [setStatus,
    setUnderDispute,
    setLastUpdated,
    setServiceProvided,
    setManagedProvider,
    setPayerProvider,
    setInvoiceShortId,
    setInvoiceClientName,
    setInvoicePayer,
    setDateDuration,
    setDisputeResolution,
    setDisputeReason,
  ]);
  return {
    isFiltered,
    lastUpdated,
    managedProvider,
    payerProvider,
    resetFilters,
    serviceProvided,
    setLastUpdated,
    setManagedProvider,
    setPayerProvider,
    setServiceProvided,
    setStatus,
    setUnderDispute,
    setInvoiceShortId,
    setInvoiceClientName,
    setInvoicePayer,
    status,
    underDispute,
    invoiceShortId,
    invoiceClientName,
    invoicePayer,
    dateDuration,
    setDateDuration,
    disputeResolution,
    setDisputeResolution,
    disputeReason,
    setDisputeReason,
  };
};

export default useInvoiceFilters;
