import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Spinner } from 'common/spinners';
import { browserHistory } from 'common/utils/browserHistory';
import Stepper from 'common/Stepper/Stepper';
import EligibilityAssessment from 'src/pages/facesheet/_id/eligibility/EligibilityAssessment';
import EligibilityConsent from 'src/pages/facesheet/_id/eligibility/EligibilityConsent';

const NewAssessment = (props) => {
  const { contactId, employeeId } = props;
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const steps = [
    {
      title: 'Consent',
      content: null,
      onStepClick: () => setStep(0),
    },
    {
      title: 'Eligibility Assessment',
      content: null,
      stepDisabled: (currentStep) => currentStep < 1,
      onStepClick: () => setStep(1),
    },
  ];
  let stepperRef = useRef();

  const answerConsent = (acceptedConsent) => {
    if (acceptedConsent) {
      stepperRef.setCurrentStep(1);
      return setStep(1);
    }
    return browserHistory.push(`/facesheet/${contactId}/eligibility/all`);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="mb-8">
        <Stepper
          ref={(el) => { stepperRef = el; }}
          steps={steps}
          initialStep={step}
          stepStyle={{ flex: '0 1 auto' }}
          stepperStyle={{ backgroundColor: '#ecf5fd' }}
        />
      </div>
      {
        step === 0 ? (
          <EligibilityConsent
            employeeId={employeeId}
            personId={contactId}
            answerConsent={answerConsent}
            setLoading={setLoading}
          />
        ) : null
      }
      {
        step === 1 ? (
          <EligibilityAssessment
            contactId={contactId}
            employeeId={employeeId}
            setLoading={setLoading}
          />
        ) : null
      }
    </div>
  );
};

NewAssessment.propTypes = {
  employeeId: PropTypes.string.isRequired,
  contactId: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  employeeId: get(state, 'globalState.currentEmployee.id'),
  contactId: get(ownProps, 'params.id'),
});

export default connect(mapStateToProps)(NewAssessment);
