/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { get } from 'lodash';
import {
  useInvalidateQueries,
} from 'src/api/APIHooks';
import Notifier from 'common/helpers/Notifier';
import { browserHistory } from 'common/utils/browserHistory';
import SurveyJSFormRenderer from '@unite-us/surveyjs/dist/components/Renderer/SurveyJSFormRenderer';
import buildResponses from '@unite-us/surveyjs/dist/components/Renderer/buildResponses';
import {
  BaseCard,
  BaseCardBody,
} from '@unite-us/ui';
import * as env from 'src/config/env/env.config';
import { screeningsApiClient } from 'src/api/config';
import { useElAssessment, EL_ASSESSMENT_QUERY_KEYS } from 'src/pages/facesheet/_id/eligibility/hooks/useEligibilityAssessments';
import {
  getIdentifiedNeedsHTML,
  formatDateToScreeningFormat,
  ELIGIBILITY_ASSESSMENT_STATUS,
} from 'src/pages/facesheet/_id/eligibility/utils';
import NotEligible from 'src/pages/facesheet/_id/eligibility/components/NotEligible';
import './EligibilityAssessment.scss';
import EligibilityAssessmentJSON from 'src/pages/facesheet/_id/eligibility/surveyjsTemplates/EligibilityAssessmentJSON';
import { submitAssessment } from 'src/pages/facesheet/_id/eligibility/hooks/eligibilityApi';

/*
  Eligibility Assessment:
  - front-end form for eligibility assessment is created in SurveyJS
  - back-end form is fetched from Screenings 2.0 API, endpoint /templates/:id
  - The visibility rules are defined in the surveyjs form
  - question ids and question option ids come from the backend and are matches to surveyjs elements


  TO DO:
  - When submitting an eligibility assessment, we currently do not save the Screening ID
    that was selected in the form. This is important for tracking the screening associated
    with this assessment. The backend does not current support saving a freetext field.
*/

export function EligibilityAssessment(props) {
  const inEditMode = get(props, 'route.mode') === 'edit';
  const assessmentId = get(props, 'params.assessment_id');
  const { data: assessment } = useElAssessment(
    assessmentId,
    { enabled: Boolean(inEditMode && assessmentId) }
  );

  const invalidateQueries = useInvalidateQueries();
  const { contactId, setLoading } = props;
  const [formJSON, setFormJSON] = useState(null);
  const [showNotEligible,] = useState(false);
  const [screenings, setScreenings] = useState([]);
  const [eligibilityAssessmentTemplate, setEligibilityAssessmentTemplate] = useState(null);
  const divRef = useRef(null); // Create a reference for a DOM element
  
  const surveyRef = useRef();
  const handleSetSurvey = (survey) => {
    surveyRef.current = survey;
  };

  const fetchMyScreenings = async () => {
    const queryParams = {
      person_id: contactId,
      type: 'screening',
    };
    const response = await screeningsApiClient.get('/screenings', { params: queryParams });
    const screens = get(response, 'data.screens', []);
    const templateIds = [];
    screens.forEach((screen) => {
      screen.name = 'Screening ' + formatDateToScreeningFormat(screen.updatedAt);
      if (templateIds.includes(screen.templateId)) { return; }
      templateIds.push(screen.templateId);
    });

    setScreenings(screens);
    return screens;
  }

  const fetchTemplate = async () => {
    const response = await screeningsApiClient.get(`/templates/${env.ELIGIBILITY_ASSESSMENT_TEMPLATE}`);
    setEligibilityAssessmentTemplate(response);;
    const questions = response.data.template.questions;

    const screenings = await fetchMyScreenings();

    EligibilityAssessmentJSON.configuration.pages[0].elements.forEach((element) => {
      if (!element.title) { return; }

      let question = questions.find((q) => {
        let questionText = q.primaryText.toLowerCase().trim();
        questionText = questionText.replace(/’/g, "'");
        let elementText = element.title.toLowerCase().trim();
        elementText = elementText.replace(/’/g, "'");
        return questionText === elementText;
      });

      if (!question) {
        console.log(`NOT FOUND "${element.title}"`);
      }

      if (element.name === 'qSelectScreening') {
        element.choices = screenings.map((screen) => ({
          value: screen.id,
          text: screen.name || screen.id, // to be replaced with screen.name
          custom: {
            question,
            questionId: question.id,
            elementName: element.name,
            option: {
              id: screen.id,
              text: screen.id,
              value: screen.id,
            }
          },
        }));
        return;
      }

      // Exceptions
      // match screenings 2.0 questions to surveyjs elements here if necessary
      if (!question) {
        // no exceptions at this time
      }

      // Set custom object in each surveyjs element/question
      if (question) {
        element.custom = {
          question,
          questionId: question.id,
          elementName: element.name,
        };

        if (element.choices) {
          element.choices.forEach((c) => {
            const option = question.options.find(
              (o) => o.text.toString().toLowerCase().localeCompare(c.text.toString().toLowerCase()) === 0
            );
            
            c.custom = {
              ...c.custom,
              question,
              elementName: element.name,
              questionId: question.id,
            };

            // skip some questions/elements (these are dynamically populated)
            if (['qSelectScreening', 'qOtherNeeds'].includes(element.name)) {
              return;
            }

            if (option) {
              c.custom.option = option;
            } else {
              console.log('CRITICAL ERROR: Question choices do not match schema', {
                elementName: element.name,
                elementChoice: c.text,
                options: question.options,
                choice: c,
                question
              });
            }
          })
        }
      } else {
        console.log('CRITICAL ERROR - NO QUESTION/ELEMENT MATCH', { element, question });
      }
    });

    setFormJSON(EligibilityAssessmentJSON);
  };

  const getNeedQuestions = () => {
    const survey = surveyRef.current.pages[0];

    const transportationQuestions = [];
    for (let i = 0; i < 5; i++) {
      transportationQuestions.push(survey.getQuestionByName(`qTransportationNeed${i + 1}`));
    }

    const foodQuestions = [];
    for (let i = 0; i < 13; i++) {
      foodQuestions.push(survey.getQuestionByName(`qFoodNeed${i + 1}`));
    }

    const housingQuestions = [];
    for (let i = 0; i < 6; i++) {
      housingQuestions.push(survey.getQuestionByName(`qHousingNeed${i + 1}`));
    }

    const utilitiesQuestions = [];
    for (let i = 0; i < 3; i++) {
      utilitiesQuestions.push(survey.getQuestionByName(`qUtilitiesNeed${i + 1}`));
    }

    const supplementalQuestions = [];
    for (let i = 0; i < 8; i++) {
      supplementalQuestions.push(survey.getQuestionByName(`qSupplementalQuestions${i + 1}`));
    }

    return {
      transportationQuestions,
      foodQuestions,
      housingQuestions,
      supplementalQuestions,
      utilitiesQuestions,
      all: [
        ...transportationQuestions,
        ...foodQuestions,
        ...housingQuestions,
        ...utilitiesQuestions,
        ...supplementalQuestions,
      ]
    }
  };

  const resetAfterScreeningSelections = () => {
    const survey = surveyRef.current;
    const surveyPage = surveyRef.current.pages[0];

    // reset other needs question
    survey.setValue('qOtherNeeds', []);
    const otherNeedsElement = surveyPage.getQuestionByName('qOtherNeeds');
    otherNeedsElement.choices = [];


    // clear previous selections
    survey.setValue('qReceiveHelp', null);
    survey.setValue('qReceivingServices', []);
    survey.setValue('qIsEnhancedPopulation', null);
    survey.setValue('qSelectEnhanced', []);
    survey.setValue('qDoesClientHaveClinicalConditions', null);
    survey.setValue('qClinicalConditions', []);

    const { all: allNeedQuestions } = getNeedQuestions();
    for (let i = 0; i < allNeedQuestions.length; i++) {
      allNeedQuestions[i].value = null;
    }
  };

  const convertNeedToChoiceText = (need) => {
    if (need === 'transportation') { return 'Transportation'; }
    if (need === 'food') { return 'Food'; }
    if (need === 'housing') { return 'Housing'; }
    if (need === 'interpersonal_safety') { return 'Interpersonal Safety'; }
    if (need === 'utilities') { return 'Utilities'; }
  };

  const setQIdentifiedNeedsSelect = (needs) => {
    const survey = surveyRef.current;
    // const needsSelected = needs.map((need) => convertNeedToChoiceText(need));
    const needsSelected = needs.map((need) => need.toLowerCase());
    // const qIdentifiedNeedsSelect = survey.getQuestionByName('qIdentifiedNeedsSelect');
    // qIdentifiedNeedsSelect.choices = needsSelected;
    survey.setValue("qIdentifiedNeedsSelect", needsSelected);
    evaluateFormVisibilities();
  };

  const renderIdentifiedNeeds = (needs) => {
    const ALL_NEEDS_AVAILABLE = [
      'transportation',
      'food',
      'housing',
      'utilities'
    ];
    needs = needs.filter((n) => ALL_NEEDS_AVAILABLE.includes(n));
    const surveyPage = surveyRef.current.pages[0];
    const qIdentifiedNeeds = surveyPage.getQuestionByName('qIdentifiedNeedsHtml');
    const htmlElement = document.getElementById(qIdentifiedNeeds.id);
    setQIdentifiedNeedsSelect(needs);

    if (htmlElement) {
      htmlElement.innerHTML = getIdentifiedNeedsHTML(needs);
    }

    const otherNeeds = ALL_NEEDS_AVAILABLE.filter((need) => !needs.includes(need));
    const otherNeedsElement = surveyPage.getQuestionByName('qOtherNeeds');

    const question = eligibilityAssessmentTemplate.data.template.questions
      .find(
        (q) => q.primaryText === otherNeedsElement.title
      );

    otherNeedsElement.choices = otherNeeds.map((need) => {
      let text = convertNeedToChoiceText(need);

      const option = question.options.find((o) => o.text.toLowerCase() === text.toLowerCase());
      if (!option) {
        console.log('otherNeeds - CRITICAL ERROR - NO OPTION MATCH', { need, text, question });
      }
      return {
        value: `${need}_id`,
        text,
        custom: {
          question,
          questionId: question.id,
          option: option,
        }
      }
    });
  };

  const hideIdentifiedNeeds = () => {
    const surveyPage = surveyRef.current.pages[0];
    const question = surveyPage.getQuestionByName('qIdentifiedNeedsHtml');
    const htmlElement = document.getElementById(question.id);
    if (htmlElement) {
      htmlElement.innerHTML = '';
    }
  };

  const selectScreening = async (screeningId) => {
    hideIdentifiedNeeds();
    const screening = await screeningsApiClient.get(`/screenings/${screeningId}`);
    resetAfterScreeningSelections();

    const identifiedNeeds = get(screening, 'data.identifiedNeeds', null);
    const needs = identifiedNeeds ? Object.keys(identifiedNeeds) : [];
    renderIdentifiedNeeds(needs);
    evaluateFormVisibilities();
  }

  // surveyjs utility function
  const getSelectedDropdownText = (questionName) => {
    const survey = surveyRef.current.pages[0];
    const dropdownQuestion = survey.getQuestionByName(questionName);
    const value = dropdownQuestion.value;
    const selectedChoice = dropdownQuestion.choices.find(choice => choice.value === value);
    const text = selectedChoice ? selectedChoice.text : null;

    return {
      text,
      value,
    };
  };

  const evaluateFormVisibilities = () => {
    const survey = surveyRef.current.pages[0];

    // check if the client has identified needs from selected screening
    const qIdentifiedNeedsHtml = document.getElementById(survey.getQuestionByName("qIdentifiedNeedsHtml").id);
    const identifiedNeedsHtml = qIdentifiedNeedsHtml ? qIdentifiedNeedsHtml.innerHTML : '';

    const htmlContainsHousing = identifiedNeedsHtml.includes("Housing");
    const htmlContainsTransportation = identifiedNeedsHtml.includes("Transportation");
    const htmlContainsFood = identifiedNeedsHtml.includes("Food");
    const htmlContainsUtilities = identifiedNeedsHtml.includes("Utilities");

    // check if the client has identified needs from "other needs" question
    const qOtherNeeds = survey.getQuestionByName("qOtherNeeds");
    const otherNeedsContainsHousing = qOtherNeeds && qOtherNeeds.value.includes("housing_id");
    const otherNeedsContainsTransportation = qOtherNeeds && qOtherNeeds.value.includes("transportation_id");
    const otherNeedsContainsFood = qOtherNeeds && qOtherNeeds.value.includes("food_id");
    const otherNeedsContainsUtilities = qOtherNeeds && qOtherNeeds.value.includes("utilities_id");

    const hasHousingNeed = htmlContainsHousing || otherNeedsContainsHousing;
    const hasTransportationNeed = htmlContainsTransportation || otherNeedsContainsTransportation;
    const hasFoodNeed = htmlContainsFood || otherNeedsContainsFood;
    const hasUtilitiesNeed = htmlContainsUtilities || otherNeedsContainsUtilities;
    
    const hasNoNeeds = !hasHousingNeed && !hasTransportationNeed && !hasFoodNeed && !hasUtilitiesNeed;
    const hasNeeds = !hasNoNeeds;

    const qIsEnhancedPopulation = survey.getQuestionByName('qIsEnhancedPopulation');
    const qNotEligible = survey.getQuestionByName('qNotEligible');
    const isQuestionIsEnhancedPopulationVisible = qIsEnhancedPopulation.visible;
    const isNotEnhancedPopulation = qIsEnhancedPopulation && qIsEnhancedPopulation.value === 'no';

    const qDoesClientHaveClinicalConditions = survey.getQuestionByName('qDoesClientHaveClinicalConditions');
    const isQDoesHaveClinicalConditionsVisible = qDoesClientHaveClinicalConditions.visible;
    const doesNotHaveClinicalCondition = qDoesClientHaveClinicalConditions.value === 'no';
    const hasClinicalCondition = qDoesClientHaveClinicalConditions.value === 'yes';

    const qIsNeedSpecificQuestionsHeader = survey.getQuestionByName('qNeedSpecificQuestionsHeader');
    const { text: isEnrolledText } = getSelectedDropdownText('qIsEnrolled')
    const isEnrolledInMedicaid = Boolean(isEnrolledText === 'Yes');

    const qSelectScreening = survey.getQuestionByName('qSelectScreening');
    const screeningIsSelected = Boolean(qSelectScreening.value);

    if (isEnrolledText === 'No') {
      hideIdentifiedNeeds();
      qSelectScreening.value = null;
    }

    const qReceiveHelp = survey.getQuestionByName('qReceiveHelp');
    const clientDoesNotWantToReceiveHelpForNeeds = qReceiveHelp.value === 'no';

    // determine the visibility of need-specific questions
    const {
      transportationQuestions,
      foodQuestions,
      housingQuestions,
      utilitiesQuestions,
      supplementalQuestions,
    } = getNeedQuestions();

    qIsNeedSpecificQuestionsHeader.visible = Boolean(hasNeeds && hasClinicalCondition);

    transportationQuestions.forEach((question) => {
      question.visible = Boolean(hasTransportationNeed && hasClinicalCondition);
    });

    foodQuestions.forEach((question) => {
      question.visible = Boolean(hasFoodNeed && hasClinicalCondition);
    });

    housingQuestions.forEach((question) => {
      question.visible = Boolean(hasHousingNeed && hasClinicalCondition);
    });

    utilitiesQuestions.forEach((question) => {
      question.visible = Boolean(hasUtilitiesNeed && hasClinicalCondition);
    });

    // check if the client wants services for their needs
    const wantServicesTransportation = survey.getQuestionByName('qTransportationNeed5');
    const clientDoesNotWantTransportationServices = get(wantServicesTransportation, 'value') === 'no';
    const wantServicesFood = survey.getQuestionByName('qFoodNeed10');
    const clientDoesNotWantFoodServices = get(wantServicesFood, 'value') === 'no';
    const wantServicesHousing = survey.getQuestionByName('qHousingNeed6');
    const clientDoesNotWantHousingServices = get(wantServicesHousing, 'value') === 'no';
    const wantServicesUtilities = survey.getQuestionByName('qUtilitiesNeed3');
    const clientDoesNotWantUtilitiesServices = get(wantServicesUtilities, 'value') === 'no';

    // determine if the client does not want services for any of their needs
    let doesNotWantServices = true;
    if (
      (hasFoodNeed && !clientDoesNotWantFoodServices) ||
      (hasHousingNeed && !clientDoesNotWantHousingServices) ||
      (hasTransportationNeed && !clientDoesNotWantTransportationServices) ||
      (hasUtilitiesNeed && !clientDoesNotWantUtilitiesServices)
    ) {
      doesNotWantServices = false;
    }

    // rules for showing the Not Eligible message
    const isNotEligible = Boolean(
      !isEnrolledInMedicaid
      || (screeningIsSelected && hasNoNeeds)
      || (screeningIsSelected && clientDoesNotWantToReceiveHelpForNeeds)
      || (screeningIsSelected && isQuestionIsEnhancedPopulationVisible && isNotEnhancedPopulation)
      || (screeningIsSelected && isQDoesHaveClinicalConditionsVisible && doesNotHaveClinicalCondition)
      || (screeningIsSelected && isQuestionIsEnhancedPopulationVisible && hasClinicalCondition && doesNotWantServices)
    );
    qNotEligible.visible = isNotEligible;

    // rules for showing Supplemental Questions section
    supplementalQuestions.forEach((question) => {
      question.visible = Boolean(
        screeningIsSelected && isQDoesHaveClinicalConditionsVisible && hasClinicalCondition && !doesNotWantServices
      );
    });
  };

  const onValueChanged = (sender, options) => {
    if (options.name === 'qSelectScreening') {
      const screeningId = options.value;
      if (screeningId) {
        selectScreening(screeningId);
      }
    }

    evaluateFormVisibilities();
  }

  const buildAnswersPayload = () => {
    const survey = surveyRef.current;

    const responses = buildResponses(surveyRef.current);
    const answers = responses
      .filter((response) => {
        if (['qSelectScreening'].includes(response.name)) {
          return false;
        }
        return true;
      })
      .map((response) => {
        let option_id = null;
        const optionsSelected = response.custom.map((c) => c.option.uuid || c.option.id);
        const questionType = get(response, 'custom[0].question.type', '');
        option_id = optionsSelected;
        if (questionType === 'select_multiple') {
          option_id = Array.isArray(optionsSelected) ? optionsSelected : [optionsSelected];
        } else if (optionsSelected.length > 1) {
          option_id = optionsSelected;
        } else {
          option_id = optionsSelected[0];
        }
        return {
          question_id: response.custom[0].questionId,
          option_id,
        }
      });

    return answers;
  };

  const getSelectedScreeningId = () => {
    const survey = surveyRef.current;
    const qSelectScreening = survey.getQuestionByName('qSelectScreening');
    return get(qSelectScreening, 'value');
  };

  const submit = async () => {
    try {
      setLoading(true);
      const survey = surveyRef.current;
      const surveyPage = survey.pages[0]

      // validates form and displays errors/warnings on the UI for required questions
      const validateForm  = () => {
        let isFormValid = true;
        surveyPage.questions.forEach(function (question) {
          if (question.hasErrors(true)) {
            isFormValid = false;
          }
        });
        return isFormValid;
      };

      const isValid = validateForm();

      if (!isValid) {
        return;
      }

      const answers = buildAnswersPayload();
      const response = await submitAssessment({
        personId: contactId,
        status: ELIGIBILITY_ASSESSMENT_STATUS.COMPLETE,
        answers,
        related_screen_id: getSelectedScreeningId(),
      });
      invalidateQueries(EL_ASSESSMENT_QUERY_KEYS.ASSESSMENTS);

      const screenId = get(response, 'data.screen.id');
      setLoading(false);
      if (screenId) {
        Notifier.dispatch(200, 'Eligibility Assessment Complete')
        browserHistory.push(`/facesheet/${contactId}/eligibility/view/${screenId}`);
        return;
      }
      Notifier.dispatch(200, 'We ran into an issue, please try again.');
    } catch (error) {
      setLoading(false);
    }
  };

  const cancel = () => {
    browserHistory.push(`/facesheet/${contactId}/eligibility/all`);
  };

  const saveDraft = async () => {
    try {
      setLoading(true);
      const answers = buildAnswersPayload();
      const response = await submitAssessment({
        personId: contactId,
        status: ELIGIBILITY_ASSESSMENT_STATUS.DRAFT,
        answers,
        related_screen_id: getSelectedScreeningId(),
      });

      invalidateQueries(EL_ASSESSMENT_QUERY_KEYS.ASSESSMENTS);
      const screenId = get(response, 'data.screen.id');
      setLoading(false);
      if (screenId) {
        browserHistory.push(`/facesheet/${contactId}/eligibility/view/${screenId}`);
        Notifier.dispatch(200, 'Eligibility Assessment Saved as Draft');
        return;
      }
      Notifier.dispatch(200, 'We ran into an issue, please try again.');
    } catch (error) {
      setLoading(false);
      console.error('saveDraft', error);
      // rollbar error here
    }
  };

  const populateForm = (assess) => {
    // TODO: populate form with assessment data
    // const survey = surveyRef.current.pages[0];
    // const screenQuestions = get(assess, 'data.screen.questions');
  };

  useEffect(() => {
    fetchTemplate();
  }, []);

  useEffect(() => {
    if (inEditMode && assessmentId && assessment) {
      populateForm(assessment);
    }
  }, [assessment]);


  if (!formJSON) { return null; }

  return (
    <div className="eligibility-assessment">
      <BaseCard className="bg-white">
        <div
          className='border border-solid border-medium-border-blue border-l-0 border-r-0 border-t-0'
        >
          <div className="px-5 py-8">
            <h2 className="text-2xl font-bold mb-2">Eligibility Assessment</h2>
            <p className="text-md">This form is to determine eligibility for HRSN services, if the client is not eligible, you can proceed to send referrals for regular client services.</p>
          </div>
        </div>

        <div className="px-6">
          <BaseCardBody>
            <SurveyJSFormRenderer
              formData={formJSON}
              formSubmission={null}
              editMode={true}
              handleSetSurveyModel={handleSetSurvey}
              onValueChanged={onValueChanged}
            />

            {
              showNotEligible ? (
                <NotEligible />
              ) : null
            }

            <div
              className="flex justify-between items-center mb-6 mt-3"
            >
              <button
                onClick={cancel}
                className="h-12 mr-4 px-16 py-3 border border-solid border-light-border-blue rounded text-action-darker-blue">
                <span className="inline-block align-middle">Cancel</span>
              </button>

              <div className="flex space-x-4">
                <button
                  onClick={saveDraft}
                  className="h-12 px-16 py-3 border border-solid border-medium-border-blue rounded text-action-darker-blue"
                >
                  <span className="inline-block align-middle mr-2">
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.9643 3.5L13 0.535714C12.7143 0.25 12.1786 0 11.7857 0H2.21429C1.25 0 0.5 0.785714 0.5 1.71429V14.2857C0.5 15.25 1.25 16 2.21429 16H14.7857C15.7143 16 16.5 15.25 16.5 14.2857V4.71429C16.5 4.32143 16.25 3.78571 15.9643 3.5ZM8.5 13.7143C7.21429 13.7143 6.21429 12.7143 6.21429 11.4286C6.21429 10.1786 7.21429 9.14286 8.5 9.14286C9.75 9.14286 10.7857 10.1786 10.7857 11.4286C10.7857 12.7143 9.75 13.7143 8.5 13.7143ZM11.9286 2.85714V6.42857C11.9286 6.67857 11.7143 6.85714 11.5 6.85714H3.21429C2.96429 6.85714 2.78571 6.67857 2.78571 6.42857V2.71429C2.78571 2.5 2.96429 2.28571 3.21429 2.28571H11.3571C11.4643 2.28571 11.5714 2.35714 11.6786 2.42857L11.7857 2.53571C11.8571 2.60714 11.9286 2.75 11.9286 2.85714Z" fill="#4467AB"/>
                    </svg>
                  </span>
                  <span className="inline-block align-middle">Save Draft</span>
                </button>
                
                <button
                  onClick={submit}
                  className="h-12 px-16 py-3 bg-action-blue rounded text-white"
                >
                  <span className="inline-block align-middle mr-2">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.651414 9.06769C0.379074 9.06769 0.15625 9.29051 0.15625 9.56285V10.5532C0.15625 10.8255 0.379074 11.0483 0.651414 11.0483H4.11757V9.06769H0.651414ZM15.7849 3.40424L12.7551 0.371359C12.6158 0.232094 12.427 0.154724 12.229 0.154724H12.0402V4.11604H16.0015V3.92726C16.0015 3.73229 15.9241 3.54351 15.7849 3.40424ZM11.0499 4.36362V0.154724H4.86031C4.44871 0.154724 4.11757 0.485865 4.11757 0.897471V9.06769H8.07888V7.04989C8.07888 6.60734 8.61428 6.38761 8.92685 6.70018L11.8855 9.68664C12.0897 9.89399 12.0897 10.2251 11.8855 10.4294L8.92375 13.4128C8.61118 13.7253 8.07579 13.5056 8.07579 13.063V11.0483H4.11757V15.2572C4.11757 15.6688 4.44871 16 4.86031 16H15.2588C15.6704 16 16.0015 15.6688 16.0015 15.2572V5.10637H11.7926C11.3841 5.10637 11.0499 4.77213 11.0499 4.36362Z" fill="white"/>
                    </svg>
                  </span>
                  <span className="inline-block align-middle text-white">
                    Submit Assessment
                  </span>
                </button>
              </div>

            </div>
          </BaseCardBody>
        </div>
      </BaseCard>
      <div ref={divRef} style={{ display: "none" }}></div>
    </div>
  );
}

EligibilityAssessment.propTypes = {
  setLoading: PropTypes.func.isRequired,
  contactId: PropTypes.string.isRequired,
  children: PropTypes.node,
};

EligibilityAssessment.defaultProps = {
  children: null,
};

export default EligibilityAssessment;
