import PropTypes from 'prop-types';
import React from 'react';
import {
  Icon,
} from '@unite-us/ui';

const CaretIcon = ({ open, style }) => (
  open ?
    <Icon icon="IconCaretUp" style={style} /> :
    <Icon icon="IconCaretDown" style={style} />
);

CaretIcon.propTypes = {
  open: PropTypes.bool.isRequired,
  style: PropTypes.object.isRequired,
};

CaretIcon.defaultProps = {
  open: false,
  style: {
    svg: {
      width: '1.5em',
      height: '1.5em',
      color: '#C5C8CC',
    },
  },
};

export default CaretIcon;
