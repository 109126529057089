import React from 'react';
import DialogV2 from 'common/modal/DialogV2';
import EmptyTable from 'src/common/tables/EmptyTable/EmptyTable';
import Pagination from 'src/common/Pagination/Pagination';
import PropTypes from 'prop-types';
import { Spinner } from 'common/spinners';
import { tableWidth } from 'common/helpers/Tables';
import { theme } from 'src/../tailwind.config';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Icon,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from '@unite-us/ui';

export const AddRelationshipDialog = ({
  cancelHandler,
  confirmationHandler,
  confirmLabel,
  dialogId,
  dialogTitle,
  confirmationBtnDisabled,
  isFetching,
  onKeyPress,
  onClick,
  onChange,
  paging,
  tableElements,
  selectedID,
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  scrollPaginationElement,
  selectElement,
  setRef,
  checkboxDefault,
  inputId,
  inputPlaceholder,
  baseCardHeaderTestId,
  searchTableId,
  labelText,
  tableHeaderColumns,
  baseCardHeaderTitle,
}) => (
  <DialogV2
    cancelHandler={cancelHandler}
    confirmationHandler={confirmationHandler}
    confirmLabel={confirmLabel}
    id={dialogId}
    ref={setRef}
    title={dialogTitle}
    confirmationBtnDisabled={confirmationBtnDisabled}
    width="6xl"
  >
    { isFetching ? <Spinner /> :
    (
      <form className="content-with-actions flex flex-row" data-testid="add-relationship-dialog-form">
        <div className="content-container w-full">
          <div
            className="antialiased"
          >
            <label
              htmlFor={inputId}
              className={`block mb-1 leading-snug font-extrabold font-medium-font normal-case text-13px
              text-text-blue ui-form-field__label--required`}
            >
              {labelText}
            </label>
            <div className="relative">
              <input
                aria-describedby={'organization__form-error'}
                className={
                  `py-2 outline-none h-38px px-4 border border-solid rounded-md shadow-sm border-dark-border-blue
                  hover:border-action-blue focus:border-action-blue focus:shadow-input-ring w-full`
                }
                id={inputId}
                onChange={(e) => onChange(e.target.value)}
                onKeyPress={onKeyPress}
                placeholder={inputPlaceholder}
                data-testid={inputId}
              />
              <button
                type="button"
                onClick={onClick}
                className="absolute inset-y-0 right-0 flex items-center p-2
                  bg-action-blue border border-solid rounded-md"
              >
                <Icon icon="IconSearch" color="white" />
              </button>
            </div>
          </div>
          <BaseCard className="py-6" noBorder>
            <div data-testid={baseCardHeaderTestId}>
              <BaseCardHeader title={baseCardHeaderTitle} noBorder className="font-bold px-1" />
            </div>
            <BaseCardBody className="py-4">
              {
                paging && paging.total_count > 0 ?
                (
                  <div data-testid={searchTableId}>
                    <Table id="addRelationshipDialogTable" selectable>
                      <TableHeader>
                        <TableRow>
                          {
                            tableHeaderColumns.map((tableHeaderColumn) => (
                              <TableHeaderColumn
                                key={tableHeaderColumn}
                              >
                                {tableHeaderColumn}
                              </TableHeaderColumn>
                            ))
                          }
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {tableElements
                          .map((tableElement) => (
                            <TableRow
                              key={`provider-row-${tableElement.id}`}
                              onCellClick={
                                (rowNumber) => selectElement(rowNumber, tableElement.id)
                              }
                              className={
                                tableElement.id === selectedID ?
                                'border-l-4 border-solid border-action-blue bg-medium-fill-blue' :
                                ''
                              }
                            >
                              <TableRowColumn style={{ width: tableWidth('large') }}>
                                {tableElement.name}
                              </TableRowColumn>
                              <TableRowColumn style={{ width: tableWidth('large') }}>
                                ({tableElement.id})
                              </TableRowColumn>
                              <TableRowColumn style={{ width: tableWidth('icon') }}>
                                {
                                  checkboxDefault(tableElement.id) &&
                                  <Icon icon="V2CheckCircle" color={theme.extend.colors['dark-border-blue']} />
                                }
                                {
                                  selectedID === tableElement.id &&
                                  <Icon icon="V2CheckCircle" color={theme.extend.colors['action-blue']} />
                                }
                              </TableRowColumn>
                            </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                ) : (
                  <EmptyTable
                    className={'p-6 border border-t-0 border-solid border-light-border-blue"'}
                    message={'No records found'}
                  />
                )
              }
              <div className="pt-6">
                <Pagination
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                  totalItemCount={paging ? paging.total_count : 0}
                  totalPageCount={paging ? paging.total_pages : 0}
                  setPageSize={setPageSize}
                  setPageNumber={setPageNumber}
                  scrollPaginationElement={scrollPaginationElement}
                />
              </div>
            </BaseCardBody>
          </BaseCard>
        </div>
      </form>
    )}
  </DialogV2>
);

  AddRelationshipDialog.propTypes = {
    cancelHandler: PropTypes.func.isRequired,
    confirmationHandler: PropTypes.func.isRequired,
    confirmLabel: PropTypes.string.isRequired,
    dialogId: PropTypes.string.isRequired,
    dialogTitle: PropTypes.string.isRequired,
    confirmationBtnDisabled: PropTypes.bool,
    isFetching: PropTypes.bool,
    onKeyPress: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    paging: PropTypes.object.isRequired,
    tableElements: PropTypes.array.isRequired,
    selectedID: PropTypes.string.isRequired,
    pageNumber: PropTypes.number.isRequired,
    setPageNumber: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    setPageSize: PropTypes.func.isRequired,
    scrollPaginationElement: PropTypes.func.isRequired,
    selectElement: PropTypes.func.isRequired,
    setRef: PropTypes.func.isRequired,
    checkboxDefault: PropTypes.func.isRequired,
    inputId: PropTypes.string,
    inputPlaceholder: PropTypes.string,
    baseCardHeaderTestId: PropTypes.string,
    searchTableId: PropTypes.string,
    labelText: PropTypes.string.isRequired,
    tableHeaderColumns: PropTypes.array.isRequired,
    baseCardHeaderTitle: PropTypes.string.isRequired,
  };

  AddRelationshipDialog.defaultProps = {
    confirmationBtnDisabled: false,
    isFetching: false,
    inputId: '',
    inputPlaceholder: '',
    baseCardHeaderTestId: '',
    searchTableId: '',
  };

export default AddRelationshipDialog;
