import {
  formatCaseData,
  formatClientData,
  formatReferralData,
} from '.';
import { MODELS } from '../constants';

const { contact, referral, servicecase } = MODELS;

function getColumnHeaders(model) {
  switch (model) {
    case contact: {
      return {
        getRowValues: formatClientData,
        headers: [
          { label: 'Client Name', value: 'full_name' },
          { label: 'Phone', value: 'phone' },
          { label: 'Email', value: 'email' },
        ],
      };
    }

    case referral: {
      return {
        getRowValues: formatReferralData,
        headers: [
          { label: 'Recipient', value: 'recipient' },
          { label: 'Client Name', value: 'full_name' },
          { label: 'Sent By', value: 'created_by' },
          { label: 'Service Type', value: 'service_type' },
          { label: 'Status', value: 'status' },
          { label: 'Last Updated', value: 'updated_at' },
        ],
      };
    }

    case servicecase: {
      return {
        getRowValues: formatCaseData,
        headers: [
          { label: 'Client Name', value: 'full_name' },
          { label: 'Program', value: 'program_name' },
          { label: 'Service Type', value: 'service_type' },
          { label: 'Status', value: 'status' },
          { label: 'Primary Worker', value: 'primary_worker' },
          { label: 'Last Updated', value: 'updated_at' },
        ],
      };
    }

    default:
      return {};
  }
}

export default getColumnHeaders;
